import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCityFees } from "../actions/cityFees";
// import { addCityFee } from "../actions/cityFees";
import { updateNeighborhood } from "../actions/neighborhoods";

const EditNeighborhood = ({
  cityFees,
  getCityFees,
  updateNeighborhood,
  location,
  neighborhoods,
}) => {
  const [neighborhoodData, setNeighborhoodData] = useState({
    name: location.state.el.name,
    cityId: location.state.el.cityId._id,
  });

  const { name, cityId } = neighborhoodData;
  const history = useHistory();
  const onChange = (e) => {
    setNeighborhoodData({
      ...neighborhoodData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    getCityFees();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    updateNeighborhood(neighborhoodData, location.state.el._id);
  };
  //   useEffect(() => {
  //     if (neighborhoods.updateNeighborhood) {
  //       setTimeout(() => history.push("/dashboard/cities"), 2000);
  //     }
  //   }, [neighborhoods.updateNeighborhood]);

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="row menu-row">
            <div className="col menu-col mr-2"></div>
          </div>
        </div>
        <div className="container login-container">
          <div className="row  login-row">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5 login-div ">
              <div className="text-center">
                <span className="chuna-zhurawa ">نوێکردنەوەی گەرەک</span>
              </div>
              <form onSubmit={onSubmit} className="login-form">
                <div className="parent-input">
                  <select
                    onChange={onChange}
                    type="text"
                    name="cityId"
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  >
                    <option value={location.state.el.cityId._id} selected>
                      {location.state.el.cityId.city}
                    </option>
                    {cityFees.cityFees.length > 0 &&
                      cityFees.cityFees.map((el) => {
                        return (
                          <option value={el._id} key={el._id}>
                            {el.city}
                          </option>
                        );
                      })}
                  </select>
                  <span className="inline-label-span">شار</span>
                </div>
                <div className="parent-input">
                  <input
                    required
                    onChange={onChange}
                    type="text"
                    name="name"
                    value={name}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  />
                  <span className="inline-label-span"> ناوی گەرەک</span>
                </div>
                {/* {neighborhoodData && (
                  <div className="spinner-grow text-warning " role="status">
                    <span className="sr-only">Loading...</span>
                  </div> */}

                {neighborhoods.neighborhoodUpdated && (
                  <div className="alert alert-success" role="alert">
                    نوێ کرایەوە
                  </div>
                )}
                {neighborhoods.setNeighborhoodUpdated && (
                  <div className="alert alert-danger" role="alert">
                    سەرکەوتوو نەبوو
                  </div>
                )}
                <div className="container">
                  <div className="row text-center d-flex justify-content-center mt-5">
                    <button
                      type="submit"
                      className="btn login-button btn-primary"
                    >
                      نوێ بکەرەوە
                    </button>
                    {/* <Link to="/login">
                      <p className="forgot-ps">وشەی نهێنیت بیر چۆتەوە ؟</p>
                    </Link> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  cityFees: state.cityFees,
  neighborhoods: state.neighborhoods,
});

export default connect(mapStateToProps, { updateNeighborhood, getCityFees })(
  EditNeighborhood
);
