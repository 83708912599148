import {
  DELETE_HISTORY,
  DELETE_HISTORY_FAILED,
  GET_HISTORIES,
  GET_HISTORIES_FAILED,
  PENDING_GET_HISTORY,
} from "../types/types";

const initialState = {
  pendingGetHistories: false,
  histories: [],
  getHistoriesFailed: false,
  historyDeleted: false,
  deleteHistoryFailed: false,
};

export default function histories(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case PENDING_GET_HISTORY:
      return {
        ...state,
        pendingGetHistories: true,
        histories: [],
        getHistoriesFailed: false,
        historyDeleted: false,
        deleteHistoryFailed: false,
      };
    case GET_HISTORIES:
      return {
        ...state,
        pendingGetHistories: false,
        histories: payload,
        getHistoriesFailed: false,
        historyDeleted: false,
        deleteHistoryFailed: false,
      };
    case GET_HISTORIES_FAILED:
      return {
        ...state,
        pendingGetHistories: false,
        histories: [],
        getHistoriesFailed: true,
        historyDeleted: false,
        deleteHistoryFailed: false,
      };
    case DELETE_HISTORY:
      return {
        ...state,
        historyDeleted: true,
        deleteHistoryFailed: false,
      };
    case DELETE_HISTORY_FAILED:
      return {
        ...state,
        historyDeleted: false,
        deleteHistoryFailed: true,
      };

    default:
      return state;
  }
}
