import React, { useEffect, Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { Confirm } from "react-st-modal";

import { connect } from "react-redux";
import { deleteItem } from "../actions/deleteDoc";
import { getNeighborhoodsByCityId } from "../actions/neighborhoods";

import "../App.css";
const MobileTable = React.lazy(() => import("../components/MobileTable"));
const Table = React.lazy(() => import("../components/Table"));
// const AddOrder = React.lazy(() => import("../components/AddOrder"));

const Neighborhoods = ({
  neighborhoods,
  getNeighborhoodsByCityId,
  deleteItem,
  location,
}) => {
  console.log(location.state);
  const [searchedVal, setSearchedVal] = useState("");

  const onChange = (e) => {
    setSearchedVal(e.target.value);
  };
  useEffect(() => {
    getNeighborhoodsByCityId(location.state.el._id);
  }, [neighborhoods.deleteNeighborhood]);

  let width = window.innerWidth;
  // console.log(location.state.id);

  return (
    <>
      <section className="sender-section">
        <div className="container">
          <div className="row menu-row">
            <div className="col menu-col mr-2">
              <Link
                to={{
                  pathname: "/dashboard/addneighborhood",
                  state: { el: location.state.el },
                }}
              >
                <i
                  className="fa fa-plus-circle add-user-icon"
                  aria-hidden="true"
                ></i>
              </Link>

              <button className="submit-button" type="submit">
                <i className="fas fa-search"></i>
              </button>
              <input
                className="form-control search-input"
                type="search"
                value={searchedVal}
                onChange={onChange}
                placeholder="گەران"
                aria-label="Search"
              />
            </div>
          </div>

          <div className="row text-center d-flex justify-content-center mb-4 mt-lg-5">
            <Suspense
              fallback={
                <div
                  className="spinner-grow text-warning page-loader"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              }
            >
              <table className="table desktop-table">
                <thead>
                  <tr>
                    <th scope="col table-th"></th>

                    {/* <th scope="col table-th" className="table-th">
                        گەرەکەکان
                      </th>
                      <th scope="col table-th" className="table-th">
                        کرێ
                      </th> */}
                    <th scope="col table-th" className="table-th">
                      گەرەک
                    </th>
                  </tr>
                </thead>
                {neighborhoods.pendingGetNeighborhoods && (
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
                {neighborhoods.neighborhoods.length > 0 &&
                  neighborhoods.neighborhoods.map((el) => {
                    return (
                      <>
                        <Table fromSender={true} key={el._id}>
                          <tbody>
                            {" "}
                            <tr>
                              <td className="icons-td-desktop">
                                <i
                                  onClick={async () => {
                                    const result = await Confirm(
                                      "دەتەوێت ئەم گەرەکە لابەریت ؟",
                                      "",
                                      "بەڵێ",
                                      "نەخێر"
                                    );

                                    if (result) {
                                      deleteItem(
                                        `neighborhoods/${el._id}`,
                                        "neighborhoods"
                                      );
                                    } else {
                                      // Сonfirmation not confirmed
                                    }
                                  }}
                                  className="fas fa-trash"
                                ></i>
                                <Link
                                  to={{
                                    pathname: "/dashboard/editneighborhood",
                                    state: { el: el },
                                  }}
                                >
                                  <i className="far fa-edit"></i>
                                </Link>
                              </td>

                              <td>{el.name}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    );
                  })}
              </table>
              {!neighborhoods.neighborhoods.length > 0 &&
                !neighborhoods.pendingGetNeighborhoods && (
                  <div className="not-found">هیچ گەرەکێک نەدۆزرایەوە</div>
                )}
            </Suspense>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStatetoProps = (state) => ({
  neighborhoods: state.neighborhoods,
});

// const mapDispatchToProps = (dispatch) => ({
//   getSenders: () => dispatch({ type: GET_SENDERS }),
//   deleteItem: () => dispatch({ type: DELETE_SENDER }),
// });
export default connect(mapStatetoProps, {
  getNeighborhoodsByCityId,
  deleteItem,
})(Neighborhoods);
