import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { server } from "../api/api";
import { baseQuery } from "../pages/announcements/announcementQueries";

export const branchApi = createApi({
  reducerPath: "branch",
  baseQuery: baseQuery,
  tagTypes: ["branch"],
  endpoints: (builder) => ({
    getBranch: builder.query({
      query: (id) => ({
        url: `/branches/${id}`,
        method: "GET",
      }),
    }),
    getBranchStatus: builder.query({
      query: (id) => ({
        url: `/branches/status`,
        method: "GET",
      }),
    }),
    getOrdersByBranch: builder.query({
      query: (params) => ({
        url: `/orders/branch/${params.id}?barCode=${params.barCode}`,
        method: "GET",
      }),
    }),
    updateOrderBranch: builder.mutation({
      query: (id) => ({
        url: `/orders/currentcity/${id}`,
        method: "PATCH",
      }),
    }),
    addUserBalance: builder.mutation({
      query: (data) => ({
        url: `/users/balance/add/${data.id}`,
        method: "PATCH",
        body: {
          balance: data.balance,
        },
      }),
    }),
    receiveCommission: builder.mutation({
      query: (id) => ({
        url: `/branches/receive/${id}`,
        method: "PATCH",
      }),
    }),
    requestReset: builder.mutation({
      query: (email) => ({
        url: `/admin/request-reset`,
        method: "POST",
        body: {
          email: email,
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ token, id, password }) => ({
        url: `/admin/reset-password`,
        method: "POST",
        body: {
          token,
          id,
          password,
        },
      }),
    }),
  }),
});

export const {
  useGetBranchQuery,
  useGetOrdersByBranchQuery,
  useUpdateOrderBranchMutation,
  useRequestResetMutation,
  useGetBranchStatusQuery,
  useResetPasswordMutation,
  useAddUserBalanceMutation,
  useReceiveCommissionMutation,
} = branchApi;
