import React, { Suspense } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import "./App.css";
import AddAdmin from "./components/AddAdmin";
import AddBranch from "./components/AddBranch";
import AddInventory from "./components/AddInventory";
import AddNeighborhood from "./components/AddNeighborhood";
import EditBranch from "./components/EditBranch";
import EditInventory from "./components/EditInventory";
import EditNeighborhood from "./components/EditNeighborhood";
import Branches from "./pages/Branches";
import Neighborhoods from "./pages/Neighborhoods";
import SpecialRequests from "./pages/SpecialRequests";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "./components/Sidebar";
import BranchDetails from "./pages/BranchDetail";
import { RequestResetPassword } from "./pages/RequestResetPassword";
import { ResetPassword } from "./pages/ResetPassword";

const Login = React.lazy(() => import("./pages/Login"));
const Home = React.lazy(() => import("./pages/Home"));
const Sender = React.lazy(() => import("./pages/Sender"));
const Inventories = React.lazy(() => import("./pages/Inventories"));
const Driver = React.lazy(() => import("./pages/Driver"));
const Cities = React.lazy(() => import("./pages/Cities"));
const Orders = React.lazy(() => import("./pages/Orders"));
const Rules = React.lazy(() => import("./pages/Rules"));
const Reports = React.lazy(() => import("./pages/Reports"));
const History = React.lazy(() => import("./pages/History"));
const Admins = React.lazy(() => import("./pages/Admins"));
const Announcement = React.lazy(() =>
  import("./pages/announcements/Announcement")
);
const AddUser = React.lazy(() => import("./components/AddUser"));
const AddCityFee = React.lazy(() => import("./components/AddCityFee"));
const EditCityFee = React.lazy(() => import("./components/EditCityFee"));
const EditRules = React.lazy(() => import("./components/EditRules"));
const AddOrder = React.lazy(() => import("./components/AddOrder"));
const AddDriver = React.lazy(() => import("./components/AddDriver"));
const EditSender = React.lazy(() => import("./components/EditSender"));
const EditAdmin = React.lazy(() => import("./components/EditAdmin"));
const EditDriver = React.lazy(() => import("./components/EditDriver"));
const SenderOrders = React.lazy(() => import("./components/SenderOrders"));
const DriverOrders = React.lazy(() => import("./components/DriverOrders"));
const EditOrder = React.lazy(() => import("./components/EditOrder"));

const App = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();

  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              className="spinner-grow text-warning page-loader"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          }
        >
          <Switch>
            <div>
              {token && (
                <div className="container">
                  <div className="row  justify-content-end align-items-center my-4">
                    <Sidebar />
                  </div>
                </div>
              )}

              {!token && <Route exact path="/login" component={Login} />}
              <Route
                exact
                path="/request-reset"
                component={RequestResetPassword}
              />
              <Route
                path="/password-reset/:id/:token"
                component={ResetPassword}
              />
              <Route exact path="/dashboard/home" component={Home} />
              <Route exact path="/dashboard/sender" component={Sender} />
              <Route exact path="/dashboard/driver" component={Driver} />
              <Route exact path="/dashboard/admins" component={Admins} />
              <Route
                exact
                path="/dashboard/announcement"
                component={Announcement}
              />
              <Route
                exact
                path="/dashboard/histories/:id"
                component={History}
              />
              <Route
                exact
                path="/dashboard/inventories"
                component={Inventories}
              />
              <Route exact path="/dashboard/cities" component={Cities} />
              <Route exact path="/dashboard/rules" component={Rules} />
              <Route exact path="/dashboard/branches" component={Branches} />
              <Route
                exact
                path="/dashboard/branches/:id"
                component={BranchDetails}
              />
              <Route exact path="/dashboard/reports" component={Reports} />
              <Route exact path="/dashboard/orders" component={Orders} />
              <Route
                exact
                path="/dashboard/specialRequests/:name"
                component={SpecialRequests}
              />
              <Route exact path="/dashboard/adduser" component={AddUser} />
              <Route
                exact
                path="/dashboard/addcityfee"
                component={AddCityFee}
              />
              <Route
                path="/dashboard/neighborhoods/:city"
                exact
                component={Neighborhoods}
              />
              <Route
                exact
                path="/dashboard/editcityfee"
                component={EditCityFee}
              />
              <Route
                exact
                path="/dashboard/addorder/:id"
                component={AddOrder}
              />
              <Route
                exact
                path="/dashboard/addinventory"
                component={AddInventory}
              />
              <Route exact path="/dashboard/addriver" component={AddDriver} />
              <Route exact path="/dashboard/addbranch" component={AddBranch} />
              <Route
                exact
                path="/dashboard/addneighborhood"
                component={AddNeighborhood}
              />
              <Route exact path="/dashboard/addadmin" component={AddAdmin} />
              <Route
                exact
                path="/dashboard/editsender"
                component={EditSender}
              />
              <Route
                exact
                path="/dashboard/editinventory"
                component={EditInventory}
              />
              <Route
                exact
                path="/dashboard/editdriver"
                component={EditDriver}
              />
              <Route exact path="/dashboard/editrules" component={EditRules} />
              <Route exact path="/dashboard/editadmin" component={EditAdmin} />
              <Route
                exact
                path="/dashboard/editorder/:id"
                component={EditOrder}
              />
              <Route
                exact
                path="/dashboard/editbranch"
                component={EditBranch}
              />
              <Route
                exact
                path="/dashboard/editneighborhood"
                component={EditNeighborhood}
              />
              <Route
                exact
                path="/dashboard/driverorders/:id"
                component={DriverOrders}
              />
              <Route
                exact
                path="/dashboard/senderorders/:id"
                component={SenderOrders}
              />

              {!token && (
                <Route path="/dashboard/*">
                  <Redirect to="/login" />
                </Route>
              )}
              {token && (
                <Route exact path="/">
                  <Redirect to="/dashboard/home" />
                </Route>
              )}
              {token && (
                <Route exact path="/login">
                  <Redirect to="/dashboard/home" />
                </Route>
              )}
            </div>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
};
const mapStatetoProps = (state) => ({
  user: state.login.user,
});
export default connect(mapStatetoProps)(App);
