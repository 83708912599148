import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/login";
import { useTranslation } from "react-i18next";
// import { POST_LOGOUT } from "../types/types";
const Sidebar = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const logout = () => {
    props.logout();
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
  };
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();
  useEffect(() => {
    pathname !== "/login" && setSidebar(false);
  }, [pathname]);

  const branch = localStorage.getItem("branch");

  return (
    <>
      <i
        onClick={() => setSidebar(!sidebar)}
        className="burger-icon fal fa-bars "
      ></i>
      <nav
        className={`sidebar ${sidebar && "animate-in-sidebar"} ${
          !sidebar && "animate-out-sidebar"
        }`}
      >
        <span
          onClick={() => setSidebar(!sidebar)}
          style={{ fontSize: "1.7rem", cursor: "pointer" }}
          className="text-light p-3"
        >
          X
        </span>
        <ul>
          <Link to="/dashboard/home">
            <li className="sidebar-li">{t("main")}</li>
          </Link>
          <Link to="/dashboard/sender">
            {" "}
            <li className="sidebar-li">{t("senders")}</li>
          </Link>
          <Link to="/dashboard/driver">
            <li className="sidebar-li">{t("drivers")}</li>
          </Link>
          <Link to="/dashboard/orders">
            <li className="sidebar-li">{t("orders")}</li>
          </Link>
          <Link to="/dashboard/inventories">
            <li className="sidebar-li">{t("inventories")}</li>
          </Link>
          <Link to="/dashboard/cities">
            <li className="sidebar-li">{t("cities")}</li>
          </Link>

          {/* {branch === "All" && ( */}
          <Link to="/dashboard/branches">
            <li className="sidebar-li">{t("branches")}</li>
          </Link>
          {/* )} */}

          <Link to="/dashboard/admins">
            <li className="sidebar-li">{t("admins")}</li>
          </Link>
          <Link to="/dashboard/announcement">
            <li className="sidebar-li">{t("announcement")}</li>
          </Link>

          <Link to="/dashboard/rules">
            <li className="sidebar-li">{t("rules")}</li>
          </Link>
          <Link to="/dashboard/reports">
            <li className="sidebar-li">{t("reports")}</li>
          </Link>

          <li onClick={logout} className="sidebar-li">
            {t("logout")}
          </li>
          <div className="d-flex justify-content-around mt-4">
            <span
              className="sidebar-li"
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("ku")}
            >
              کوردی
            </span>
            <span
              style={{ borderBottom: "none" }}
              className="sidebar-li"
              onClick={() => changeLanguage("ar")}
            >
              عربی
            </span>
            <span
              style={{ borderBottom: "none" }}
              className="sidebar-li"
              onClick={() => changeLanguage("en")}
            >
              English
            </span>
          </div>
        </ul>
      </nav>
    </>
  );
};
const mapStateToProps = (state) => ({
  login: state.login,
});

// const mapDispatchToProps = (dispatch) => ({
//   logout: () => dispatch({ type: POST_LOGOUT }),
// });
export default connect(mapStateToProps, { logout })(Sidebar);
