import {
  ADD_ADMIN,
  ADD_ADMIN_FAILED,
  DELETE_ADMIN,
  DELETE_ADMIN_FAILED,
  GET_ADMINS,
  GET_ADMINS_FAILED,
  PATCH_ADMIN,
  PATCH_ADMIN_FAILED,
  PENDING_ADD_ADMIN,
  PENDING_GET_ADMINS,
  PENDING_PATCH_ADMIN,
} from "../types/types";

const initialState = {
  pendingGetAdmins: false,
  admins: [],
  getAdminsFailed: false,
  pendingAddAdmin: false,
  addAdmin: false,
  addAdminFailed: false,
  pendingPatchAdmin: false,
  patchAdmin: false,
  patchAdminFailed: false,
  deleteAdmin: false,
  deleteAdminFailed: false,
};

export default function adminsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PENDING_GET_ADMINS:
      return {
        ...state,
        pendingGetAdmins: true,
        admins: [],
        getAdminsFailed: false,
        pendingAddAdmin: false,
        addAdmin: false,
        addAdminFailed: false,
        pendingPatchAdmin: false,
        patchAdmin: false,
        patchAdminFailed: false,
        deleteAdmin: false,
        deleteAdminFailed: false,
      };
    case GET_ADMINS:
      return {
        ...state,
        pendingGetAdmins: false,
        admins: payload,
        getAdminsFailed: false,
      };
    case GET_ADMINS_FAILED:
      return {
        ...state,
        pendingGetAdmins: false,
        admins: [],
        getAdminsFailed: true,
      };
    case PENDING_ADD_ADMIN:
      return {
        ...state,
        pendingAddAdmin: true,
        addAdmin: false,
        addAdminFailed: false,
      };
    case ADD_ADMIN:
      return {
        ...state,
        pendingAddAdmin: false,
        addAdmin: true,
        addAdminFailed: false,
      };
    case ADD_ADMIN_FAILED:
      return {
        ...state,
        pendingAddAdmin: false,
        addAdmin: false,
        addAdminFailed: true,
      };
    case DELETE_ADMIN:
      return {
        ...state,
        deleteAdmin: true,
        deleteAdminFailed: false,
      };
    case DELETE_ADMIN_FAILED:
      return {
        ...state,
        deleteAdmin: false,
        deleteAdminFailed: true,
      };
    case PENDING_PATCH_ADMIN:
      return {
        ...state,
        pendingPatchAdmin: true,
        patchAdmin: false,
        patchAdminFailed: false,
      };
    case PATCH_ADMIN:
      return {
        ...state,
        pendingPatchAdmin: false,
        patchAdmin: true,
        patchAdminFailed: false,
      };
    case PATCH_ADMIN_FAILED:
      return {
        ...state,
        pendingPatchAdmin: false,
        patchAdmin: false,
        patchAdminFailed: true,
      };
    default:
      return state;
  }
}
