import React, { useEffect, Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { Confirm } from "react-st-modal";

import { connect } from "react-redux";
import { deleteItem } from "../actions/deleteDoc";
import { getBranches } from "../actions/branches";

import "../App.css";
import { useTranslation } from "react-i18next";
import { getUserBranch } from "../utils/helpers";
const MobileTable = React.lazy(() => import("../components/MobileTable"));
const Table = React.lazy(() => import("../components/Table"));
// const AddOrder = React.lazy(() => import("../components/AddOrder"));

const Branches = ({ branches, getBranches, deleteItem, location }) => {
  const { t } = useTranslation();
  const [searchedVal, setSearchedVal] = useState("");

  const onChange = (e) => {
    setSearchedVal(e.target.value);
  };
  useEffect(() => {
    getBranches();
  }, [branches.deleteBranch]);

  const branch = localStorage.getItem("branch");

  return (
    <>
      <section className="sender-section">
        <div className="container">
          <div className="row menu-row">
            <div className="col menu-col mr-2">
              {branch === "All" && (
                <Link to="/dashboard/addbranch">
                  <i
                    className="fa fa-plus-circle add-user-icon"
                    aria-hidden="true"
                  ></i>
                </Link>
              )}
            </div>
          </div>

          <div className="row text-center d-flex justify-content-center mb-4 mt-lg-5">
            <Suspense
              fallback={
                <div
                  className="spinner-grow text-warning page-loader"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              }
            >
              <div className="table-responsive">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col table-th"></th>

                      {branch === "All" && (
                        <th scope="col table-th" className="table-th">
                          {t("commission")}
                        </th>
                      )}
                      <th scope="col table-th" className="table-th">
                        {t("branch")}
                      </th>
                    </tr>
                  </thead>
                  {branches.pendingGetBranches && (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                  {branches.branches.length > 0 &&
                    branches.branches
                      .filter((el) => el.branchName !== getUserBranch())
                      .map((el) => {
                        return (
                          <>
                            <Table fromSender={true} key={el._id}>
                              <tbody>
                                <tr>
                                  <td className="icons-td-desktop">
                                    {branch === "All" &&
                                      el.branchName !== "All" && (
                                        <>
                                          <i
                                            onClick={async () => {
                                              const result = await Confirm(
                                                t("are_you_sure_about_this"),
                                                "",
                                                t("yes"),
                                                "no"
                                              );

                                              if (result) {
                                                deleteItem(
                                                  `branches/${el._id}`,
                                                  "branches"
                                                );
                                              } else {
                                                // Сonfirmation not confirmed
                                              }
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                          <Link
                                            to={{
                                              pathname: "/dashboard/editbranch",
                                              state: { el: el },
                                            }}
                                          >
                                            <i className="far fa-edit"></i>
                                          </Link>
                                        </>
                                      )}
                                  </td>

                                  {branch === "All" && <td>{el.commission}</td>}
                                  <Link to={`/dashboard/branches/${el._id}`}>
                                    <td>{el.branchName}</td>
                                  </Link>
                                </tr>
                              </tbody>
                            </Table>
                          </>
                        );
                      })}
                </table>
              </div>
            </Suspense>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStatetoProps = (state) => ({
  branches: state.branches,
});

export default connect(mapStatetoProps, {
  getBranches,
  deleteItem,
})(Branches);
