import api from "../api/api";
import {
  ADD_ADMIN,
  GET_ADMINS,
  GET_ADMINS_FAILED,
  PENDING_ADD_ADMIN,
  PENDING_GET_ADMINS,
  ADD_ADMIN_FAILED,
  PENDING_PATCH_ADMIN,
  PATCH_ADMIN,
  PATCH_ADMIN_FAILED,
} from "../types/types";

export const getAdmins = (searchKey) => async (dispatch) => {
  dispatch({
    type: PENDING_GET_ADMINS,
  });
  try {
    const res = await api.get(`/admin?searchKey=${searchKey}`);
    dispatch({
      type: GET_ADMINS,
      payload: res.data.admins,
    });
  } catch (e) {
    dispatch({
      type: GET_ADMINS_FAILED,
      payload: e.message,
    });
  }
};
export const addAdmin = (data) => async (dispatch) => {
  dispatch({
    type: PENDING_ADD_ADMIN,
  });
  try {
    const res = await api.post("/admin/signup", data);
    dispatch({
      type: ADD_ADMIN,
      payload: res.data.admin,
    });
  } catch (e) {
    dispatch({
      type: ADD_ADMIN_FAILED,
    });
  }
};
export const updateAdmin = (data, id) => async (dispatch) => {
  let newDat = null;
  if (!data.password  && !data.confirmPassword) {
    delete data.password;
    delete data.confirmPassword;
    newDat = data;
  }
  dispatch({
    type: PENDING_PATCH_ADMIN,
  });

  try {
    const res = await api.patch(`/admin/${id}`, newDat);
    dispatch({
      type: PATCH_ADMIN,
      payload: res.data.admin,
    });
  } catch (e) {
    dispatch({
      type: PATCH_ADMIN_FAILED,
    });
  }
};
