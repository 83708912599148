import {
  GET_ORDERS,
  GET_ORDERS_FAILED,
  PENDING_GET_ORDERS,
  PENDING_UPDATE_ORDER,
  UPDATE_ORDER,
  UPDATE_ORDER_FAILED,
} from "../types/types";

const initialState = {
  failed: false,
  orders: [],
  pending: false,
  pendingUpdate: false,
  orderUpdated: false,
  orderUpdateFailed: false,
};

export default function getOrders(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: payload,
        failed: false,
        pending: false,
        pendingUpdate: false,
        orderUpdated: false,
        orderUpdateFailed: false,
      };
    case GET_ORDERS_FAILED:
      return {
        ...state,
        failed: true,
        orders: [],
        pending: false,
        pendingUpdate: false,
      };
    case PENDING_GET_ORDERS:
      return {
        ...state,
        pending: true,
        failed: false,
        orders: [],
        pendingUpdate: false,
        orderUpdated: false,
        orderUpdateFailed: false,
      };
    case PENDING_UPDATE_ORDER:
      return {
        ...state,
        pendingUpdate: true,
        orderUpdated: false,
        orderUpdateFailed: false,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orderUpdated: true,
        orderUpdateFailed: false,
        pendingUpdate: false,
      };
    case UPDATE_ORDER_FAILED:
      return {
        ...state,
        orderUpdated: false,
        orderUpdateFailed: true,
        pendingUpdate: false,
      };
    default:
      return state;
  }
}
