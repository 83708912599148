import React, { useEffect, Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { Confirm } from "react-st-modal";

import Moment from "react-moment";

import { getUserSpecialRequests } from "../actions/specialrequests";
import { connect } from "react-redux";
import { deleteItem } from "../actions/deleteDoc";

import "../App.css";
import { useTranslation } from "react-i18next";
const MobileTable = React.lazy(() => import("../components/MobileTable"));
const Table = React.lazy(() => import("../components/Table"));
// const AddOrder = React.lazy(() => import("../components/AddOrder"));

const History = ({
  getUserSpecialRequests,
  specialRequests,
  deleteItem,
  location,
}) => {
  const { t } = useTranslation();

  const [searchedVal, setSearchedVal] = useState("");

  const onChange = (e) => {
    setSearchedVal(e.target.value);
  };
  useEffect(() => {
    getUserSpecialRequests(location.state.el._id);
  }, [specialRequests.deleteSpecialRequest]);

  // console.log(location.state.id);

  return (
    <>
      <section className="sender-section">
        <div className="container">
          <div className="row menu-row">
            <div className="col menu-col mr-2"></div>
          </div>

          <div className="row text-center d-flex justify-content-center mb-4 mt-lg-5">
            <Suspense
              fallback={
                <div
                  className="spinner-grow text-warning page-loader"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              }
            >
              <table className="table desktop-table">
                <thead>
                  <tr>
                    <th scope="col table-th"></th>

                    <th scope="col table-th" className="table-th">
                      {t("edit")}
                    </th>
                    <th scope="col table-th" className="table-th">
                      {t("quantity")}
                    </th>
                    <th scope="col table-th" className="table-th">
                      {t("notes")}
                    </th>
                    <th scope="col table-th" className="table-th">
                      {t("address")}
                    </th>
                    <th scope="col table-th" className="table-th">
                      {t("driver")}
                    </th>
                    <th scope="col table-th" className="table-th">
                      {t("images")}
                    </th>
                    <th scope="col table-th" className="table-th">
                      {t("accepted")}
                    </th>
                  </tr>
                </thead>
                {specialRequests.pendingGetSpecialRequests && (
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
                {specialRequests.specialRequests.length > 0 &&
                  specialRequests.specialRequests.map((el) => {
                    return (
                      <>
                        <Table fromSender={true} key={el._id}>
                          <tbody>
                            {" "}
                            <tr>
                              <td className="icons-td-desktop">
                                <i
                                  onClick={async () => {
                                    const result = await Confirm(
                                      t("are_you_sure_about_this"),
                                      "",
                                      t("yes"),
                                      t("no")
                                    );

                                    if (result) {
                                      deleteItem(
                                        `inventoryrequests/${el._id}`,
                                        "inventoryrequests"
                                      );
                                    } else {
                                      // Сonfirmation not confirmed
                                    }
                                  }}
                                  className="fas fa-trash"
                                ></i>
                              </td>

                              <td>
                                <Moment format="YYYY/MM/DD">
                                  {el.createdAt}
                                </Moment>
                              </td>
                              <td>{el.quantity}</td>
                              <td>{el.notes}</td>
                              <td>
                                {el.currentLocation.map((loc) => {
                                  return <span className="mx-2">{loc}</span>;
                                })}
                              </td>
                              <td>{el.driverId && el.driverId.fullName}</td>
                              <td>
                                {el.images.length > 0 && (
                                  <img
                                    style={{ maxWidth: "100px" }}
                                    src={`https://api.sardam.potan.co/${el.images[0]}`}
                                    alt={"request"}
                                  />
                                )}
                              </td>
                              <td>{el.accepted ? "بەڵێ" : "نەخێر"}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    );
                  })}
              </table>
            </Suspense>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStatetoProps = (state) => ({
  specialRequests: state.specialRequests,
});

// const mapDispatchToProps = (dispatch) => ({
//   getSenders: () => dispatch({ type: GET_SENDERS }),
//   deleteItem: () => dispatch({ type: DELETE_SENDER }),
// });
export default connect(mapStatetoProps, { getUserSpecialRequests, deleteItem })(
  History
);
