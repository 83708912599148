import {
  DELETE_SPECIAL_REQUEST,
  DELETE_SPECIAL_REQUEST_FAILED,
  GET_SPECIAL_REQUESTS,
  GET_SPECIAL_REQUESTS_FAILED,
  PENDING_GET_SPECIAL_REQUESTS,
} from "../types/types";

const initialState = {
  pendingGetSpecialRequests: false,
  specialRequests: [],
  getSpecialRequestsFailed: false,
  deleteSpecialRequest: false,
  deleteSpecialRequestFailed: false,
};
export default function specialRequests(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PENDING_GET_SPECIAL_REQUESTS:
      return {
        ...state,
        pendingGetSpecialRequests: true,
        specialRequests: [],
        getSpecialRequestsFailed: false,
        deleteSpecialRequest: false,
        deleteSpecialRequestFailed: false,
      };
    case GET_SPECIAL_REQUESTS:
      return {
        ...state,
        pendingGetSpecialRequests: false,
        specialRequests: payload,
        getSpecialRequestsFailed: false,
        deleteSpecialRequest: false,
        deleteSpecialRequestFailed: false,
      };
    case GET_SPECIAL_REQUESTS_FAILED:
      return {
        ...state,
        pendingGetSpecialRequests: false,
        specialRequests: [],
        getSpecialRequestsFailed: true,
        deleteSpecialRequest: false,
        deleteSpecialRequestFailed: false,
      };
    case DELETE_SPECIAL_REQUEST:
      return {
        ...state,
        pendingGetSpecialRequests: false,
        getSpecialRequestsFailed: false,
        deleteSpecialRequest: true,
        deleteSpecialRequestFailed: false,
      };
    case DELETE_SPECIAL_REQUEST_FAILED:
      return {
        ...state,
        pendingGetSpecialRequests: false,
        getSpecialRequestsFailed: false,
        deleteSpecialRequest: false,
        deleteSpecialRequestFailed: true,
      };
    default:
      return state;
  }
}
