import api from "../api/api";
import {
  GET_SPECIAL_REQUESTS,
  GET_SPECIAL_REQUESTS_FAILED,
  PENDING_GET_SPECIAL_REQUESTS,
} from "../types/types";

export const getUserSpecialRequests = (id) => async (dispatch) => {
  dispatch({
    type: PENDING_GET_SPECIAL_REQUESTS,
  });
  try {
    const res = await api.get(`/inventoryrequests/userrequests/${id}`);
    dispatch({
      type: GET_SPECIAL_REQUESTS,
      payload: res.data.requests,
    });
  } catch (e) {
    dispatch({
      type: GET_SPECIAL_REQUESTS_FAILED,
    });
    alert(e.response.data.message);
  }
};
