import api from "../api/api";

export function containsObject(obj, list) {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i].value === obj._id) {
      return true;
    }
  }

  return false;
}

export const getCurrentUser = async (id) => {
  const res = await api.get(`/users/${id}`);
  return res.data?.user;
};

export const isMainAdmin = () => {
  const branch = localStorage.getItem("userBranch");

  if (branch === "All") {
    return true;
  }
  return false;
};

export const getUserBranch = () => {
  const branch = localStorage.getItem("branch");
  return branch;
};

export const getUserRole = () => {
  const role = localStorage.getItem("role");
  return role;
};
