  import { GET_CITIES, GET_CITIES_FAILED } from "../types/types";

const initialState = {
  cities: [],
  failed: false,
};

export default function getCities(state = initialState, action) {
  const { payload, type } = action;
  switch(type){
    case GET_CITIES : 
    return {
        ...state, 
        cities: payload
    };
    case GET_CITIES_FAILED : 
    return{
        ...state,
        cities: [],
        failed: true
    };
    default : 
    return state;
    }
  }

