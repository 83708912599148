import api from "../api/api";
import {
  ADD_BRANCH,
  ADD_BRANCH_FAILED,
  GET_BRANCHES,
  GET_BRANCHES_FAILED,
  PENDING_ADD_BRANCH,
  PENDING_GET_BRANCHES,
  UPDATE_BRANCH,
  UPDATE_BRANCH_FAILED,
} from "../types/types";

export const getBranches = () => async (dispatch) => {
  dispatch({
    type: PENDING_GET_BRANCHES,
  });
  try {
    const res = await api.get("/branches");
    dispatch({
      type: GET_BRANCHES,
      payload: res.data.branches,
    });
  } catch (e) {
    dispatch({
      type: GET_BRANCHES_FAILED,
    });
  }
};
export const addBranch = (data) => async (dispatch) => {
  dispatch({
    type: PENDING_ADD_BRANCH,
  });
  try {
    await api.post("/branches", data);
    dispatch({
      type: ADD_BRANCH,
    });
  } catch (e) {
    dispatch({
      type: ADD_BRANCH_FAILED,
    });
  }
};

export const editBranch = (data, id) => async (dispatch) => {
  try {
    await api.patch(`/branches/${id}`, data);
    dispatch({
      type: UPDATE_BRANCH,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_BRANCH_FAILED,
    });
  }
};
