import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
// import { ADD_SENDER } from "../types/types";
import { addAdmin } from "../actions/admins";
import { getCityFees } from "../actions/cityFees";
import api from "../api/api";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { getUserRole, isMainAdmin } from "../utils/helpers";

const AddAdmin = ({ addAdmin, admins, getCityFees, cityFees }) => {
  const [branchesData, setBranchesData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [role, setRole] = useState("moderator");

  const [userData, setUserData] = useState({
    name: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    email: "",
  });

  const { name, password, email, phoneNumber, confirmPassword } = userData;
  const history = useHistory();
  const { t } = useTranslation();

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const getBranches = () => {
    api.get("/branches").then((res) =>
      setBranchesData(
        res.data.branches.map((el) => {
          return { value: el._id, label: el.branchName };
        })
      )
    );
  };
  useEffect(() => {
    getBranches();
  }, []);

  const onSubmit = (e) => {
    const data = {
      ...userData,
      branch: selectedBranch?.value,
      cityId: selectedCity?.value,
      role: role.value,
    };
    e.preventDefault();
    addAdmin(data);
  };
  useEffect(() => {
    if (admins.addAdmin) {
      setTimeout(() => history.push("/dashboard/admins"), 2000);
    }
  }, [admins.addAdmin]);
  useEffect(() => {
    getCityFees({ own: true });
  }, []);
  useEffect(() => {
    setCities(
      cityFees.cityFees.map((el) => {
        return {
          value: el._id,
          label: el.city,
        };
      })
    );
  }, [cityFees.cityFees.length]);

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="row menu-row">
            <div className="col menu-col mr-2"></div>
          </div>
        </div>
        <div className="container login-container">
          <div className="row  login-row">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5 login-div ">
              <div className="text-center">
                <span className="chuna-zhurawa ">{t("add")}</span>
              </div>
              <form onSubmit={onSubmit} className="login-form">
                <div className="parent-input">
                  <input
                    required
                    onChange={onChange}
                    name="name"
                    value={name}
                    type="text"
                    className="form-control input-inline"
                    id="name"
                    aria-describedby="name"
                  />
                  <span className="inline-label-span">{t("name")}</span>
                </div>

                <div className="parent-input">
                  <input
                    required
                    onChange={onChange}
                    type="number"
                    name="phoneNumber"
                    value={phoneNumber}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  />
                  <span className="inline-label-span">{t("phone_number")}</span>
                </div>
                <div className="parent-input">
                  <input
                    required
                    type="text"
                    name="email"
                    onChange={onChange}
                    value={email}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  />
                  <span className="inline-label-span">{t("email")}</span>
                </div>
                <div className="parent-input">
                  <input
                    required
                    type="password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  />
                  <span className="inline-label-span"> {t("password")}</span>
                </div>
                <div className="parent-input">
                  <input
                    required
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={onChange}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  />
                  <span className="inline-label-span">{t("reenter")}</span>
                </div>
                <div className="d-flex mr-3">
                  <Select
                    isRtl
                    isClearable
                    required
                    className="w-100 px-3"
                    value={selectedCity}
                    onChange={setSelectedCity}
                    options={cities}
                    placeholder={t("choose_a_city")}
                  />
                  <span className="inline-label-span"> {t("city")}</span>
                </div>
                {isMainAdmin() && (
                  <div className="d-flex mr-3 mt-3">
                    <Select
                      isClearable
                      isRtl
                      className="w-100 px-3"
                      value={selectedBranch}
                      onChange={setSelectedBranch}
                      options={branchesData}
                      placeholder={t("choose_a_branch")}
                    />
                    <span className="inline-label-span"> {t("branch")}</span>
                  </div>
                )}
                {getUserRole() === "admin" && (
                  <div className="d-flex mr-3 mt-3">
                    <Select
                      isRtl
                      className="w-100 px-3"
                      value={role}
                      onChange={setRole}
                      options={[
                        { label: "admin", value: "admin" },
                        { label: "moderator", value: "moderator" },
                      ]}
                      placeholder={t("role")}
                    />
                    <span className="inline-label-span">{t("role")}</span>
                  </div>
                )}

                {admins.addAdminFailed && (
                  <div className="alert alert-danger" role="alert">
                    سەرکەوتوو نەبوو
                  </div>
                )}
                {admins.addAdmin && (
                  <div className="alert alert-success" role="alert">
                    سەرکەوتووبوو
                  </div>
                )}
                {admins.pendingAddAdmin && (
                  <div className="spinner-grow text-warning " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}

                <div className="container">
                  <div className="row text-center d-flex justify-content-center mt-5">
                    <button
                      type="submit"
                      className="btn login-button btn-primary"
                    >
                      زیادیبکە
                    </button>
                    {/* <Link to="/login">
                      <p className="forgot-ps">وشەی نهێنیت بیر چۆتەوە ؟</p>
                    </Link> */}
                  </div>
                </div>

                {/* {failed && (
                  <div className="alert alert-danger" role="alert">
                    وشەی نهێنی یان ناو هەڵەیە !
                  </div>
                )} */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  admins: state.admins,
  cityFees: state.cityFees,
});
// const mapDispatchToProps = (dispatch) => ({
//   addSender: () => dispatch({ type: ADD_SENDER }),
// });

export default connect(mapStateToProps, { addAdmin, getCityFees })(AddAdmin);
