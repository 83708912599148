import api from "../api/api";
import {
  DELETE_ADMIN,
  DELETE_ADMIN_FAILED,
  DELETE_BRANCH,
  DELETE_BRANCH_FAILED,
  DELETE_CITY_FEE,
  DELETE_CITY_FEE_FAILED,
  DELETE_DRIVER,
  DELETE_DRIVER_ORDER,
  DELETE_INVENTORY,
  DELETE_INVENTORY_FAILED,
  DELETE_NEIGHBORHOOD,
  DELETE_NEIGHBORHOOD_FAILED,
  DELETE_REPORT,
  DELETE_REPORT_FAILED,
  DELETE_SENDER,
  DELETE_SENDER_FAILED,
  DELETE_SENDER_ORDER,
  DELETE_SENDER_ORDER_FAILED,
  DELETE_SPECIAL_REQUEST,
  DELETE_SPECIAL_REQUEST_FAILED,
} from "../types/types";

export const deleteItem = (url, field) => async (dispatch) => {
  try {
    const res = await api.delete(`${url}`);
    if (field === "driver") {
      dispatch({
        type: DELETE_DRIVER,
        payload: res,
      });
    } else if (field === "driverorder") {
      dispatch({
        type: DELETE_DRIVER_ORDER,
        payload: res,
      });
    } else if (field === "sender") {
      dispatch({
        type: DELETE_SENDER,
      });
    } else if (field === "senderOrder") {
      dispatch({
        type: DELETE_SENDER_ORDER,
        payload: res,
      });
    } else if (field === "inventories") {
      dispatch({
        type: DELETE_INVENTORY,
        payload: res,
      });
    } else if (field === "admin") {
      dispatch({
        type: DELETE_ADMIN,
        payload: res,
      });
    } else if (field === "report") {
      dispatch({
        type: DELETE_REPORT,
      });
    } else if (field === "neighborhoods") {
      dispatch({
        type: DELETE_NEIGHBORHOOD,
      });
    } else if (field === "branches") {
      dispatch({
        type: DELETE_BRANCH,
      });
    } else if (field === "cityFees") {
      dispatch({
        type: DELETE_CITY_FEE,
      });
    } else if (field === "inventoryrequests") {
      dispatch({
        type: DELETE_SPECIAL_REQUEST,
      });
    } else {
      return;
    }
  } catch (e) {
    if (field === "senderOrder") {
      alert(e.response.data.message);

      dispatch({
        type: DELETE_SENDER_ORDER_FAILED,
        payload: e.message,
      });
    } else if (field === "sender") {
      alert(e.response.data.message);

      dispatch({
        type: DELETE_SENDER_FAILED,
      });
    } else if (field === "inventories") {
      alert(e.response.data.message);

      dispatch({
        type: DELETE_INVENTORY_FAILED,
      });
    } else if (field === "admin") {
      alert(e.response.data.message);

      dispatch({
        type: DELETE_ADMIN_FAILED,
      });
    } else if (field === "cityFees") {
      alert(e.response.data.message);

      dispatch({
        type: DELETE_CITY_FEE_FAILED,
      });
    } else if (field === "reports") {
      alert(e.response.data.message);

      dispatch({
        type: DELETE_REPORT_FAILED,
      });
    } else if (field === "driverorder") {
      alert(e.response.data.message);
    } else if (field === "neighborhoods") {
      dispatch({
        type: DELETE_NEIGHBORHOOD_FAILED,
      });
    } else if (field === "branches") {
      dispatch({
        type: DELETE_BRANCH_FAILED,
      });
    } else if (field === "inventoryrequests") {
      dispatch({
        type: DELETE_SPECIAL_REQUEST_FAILED,
      });
    } else {
      return;
    }
  }
};
