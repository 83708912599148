import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { localhost, server } from "../../api/api";

export const baseQuery = fetchBaseQuery({
  baseUrl: server,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    headers.set("authorization", `Bearer ${token}`);
    return headers;
  },
});

export const announcementApi = createApi({
  reducerPath: "announcements",
  baseQuery,
  tagTypes: ["Announcements"],
  endpoints: (builder) => ({
    getAnnouncements: builder.query({
      query: (query) => ({
        url: `/announcement/`,
        method: "GET",
      }),
    }),
    clearAnnouncement: builder.mutation({
      query: (id) => ({
        url: `/announcement/clear/${id}`,
        method: "PATCH",
      }),
    }),
    updateAnnouncement: builder.mutation({
      query: ({ id, announcement }) => ({
        url: `/announcement/${id}`,
        method: "PATCH",
        body: {
          announcement: announcement,
        },
      }),
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useClearAnnouncementMutation,
  useUpdateAnnouncementMutation,
} = announcementApi;
