import {
  ADD_INVENTORY,
  ADD_INVENTORY_FAILED,
  DELETE_INVENTORY,
  DELETE_INVENTORY_FAILED,
  GET_INVENTORIES,
  GET_INVENTORIES_FAILED,
  PENDING_ADD_INVENTORY,
  PENDING_GET_INVENTORIES,
  PENDING_UPDATE_INVENTORY,
  UPDATE_INVENTORY,
  UPDATE_INVENTORY_FAILED,
} from "../types/types";

const initialState = {
  inventories: [],
  pendingGetInventories: false,
  getInventories: false,
  getInventoriesFailed: false,
  pendingAddInventory: false,
  inventoryAdded: false,
  addingInventoryFailed: false,
  inventoryDeleted: false,
  deleteInventoryFailed: false,
  pendingUpdateInventory: false,
  inventoryUpdated: false,
  updatingInventoryFailed: false,
};

export default function getInventories(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case PENDING_GET_INVENTORIES:
      return {
        ...state,
        inventories: [],
        pendingGetInventories: true,
        getInventories: false,
        getInventoriesFailed: false,
        pendingAddInventory: false,
        inventoryAdded: false,
        addingInventoryFailed: false,
        inventoryDeleted: false,
        deleteInventoryFailed: false,
        pendingUpdateInventory: false,
        inventoryUpdated: false,
        updatingInventoryFailed: false,
      };
    case GET_INVENTORIES:
      return {
        ...state,
        pendingGetInventories: false,
        inventories: payload,
        getInventoriesFailed: false,
      };
    case GET_INVENTORIES_FAILED:
      return {
        ...state,
        pendingGetInventories: false,
        getInventories: false,
        getInventoriesFailed: true,
      };
    case PENDING_ADD_INVENTORY:
      return {
        ...state,
        pendingAddInventory: true,
        inventoryAdded: false,
        addingInventoryFailed: false,
      };
    case ADD_INVENTORY:
      return {
        ...state,
        pendingAddInventory: false,
        inventoryAdded: true,
        addingInventoryFailed: false,
      };
    case ADD_INVENTORY_FAILED:
      return {
        ...state,
        pendingAddInventory: false,
        inventoryAdded: false,
        addingInventoryFailed: true,
      };
    case DELETE_INVENTORY:
      return {
        ...state,
        inventoryDeleted: true,
        deleteInventoryFailed: false,
      };
    case DELETE_INVENTORY_FAILED:
      return {
        ...state,
        inventoryDeleted: false,
        deleteInventoryFailed: true,
      };
    case PENDING_UPDATE_INVENTORY:
      return {
        ...state,
        pendingUpdateInventory: true,
        inventoryUpdated: false,
        updatingInventoryFailed: false,
      };
    case UPDATE_INVENTORY:
      return {
        ...state,
        pendingUpdateInventory: false,
        inventoryUpdated: true,
        updatingInventoryFailed: false,
      };
    case UPDATE_INVENTORY_FAILED:
      return {
        ...state,
        pendingUpdateInventory: false,
        inventoryUpdated: false,
        updatingInventoryFailed: true,
      };

    default:
      return state;
  }
}
