import {
  GET_RULES,
  GET_RULES_FAILED,
  PENIDNG_GET_RULES,
  UPDATE_RULES,
  UPDATE_RULES_FAILED,
} from "../types/types";

const initialState = {
  pendingGetRules: false,
  rules: [],
  getRulesFailed: false,
  addRules: false,
  addRulesFailed: false,
  deleteRules: false,
  deleteRulesFailed: false,
  updateRules: false,
  updateRulesFailed: false,
};

export default function rules(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case PENIDNG_GET_RULES:
      return {
        ...state,
        pendingGetRules: true,
        rules: [],
        getRulesFailed: false,
        addRules: false,
        addRulesFailed: false,
        deleteRules: false,
        deleteRulesFailed: false,
        updateRules: false,
        updateRulesFailed: false,
      };
    case GET_RULES:
      return {
        ...state,
        rules: payload,
        pendingGetRules: false,

        getRulesFailed: false,
      };
    case GET_RULES_FAILED:
      return {
        ...state,
        getRulesFailed: true,
        pendingGetRules: false,
        rules: [],
      };
    case UPDATE_RULES:
      return {
        ...state,
        updateRules: true,
        updateRulesFailed: false,
      };
    case UPDATE_RULES_FAILED:
      return {
        ...state,
        updateRules: false,
        updateRulesFailed: true,
      };
    default:
      return state;
  }
}
