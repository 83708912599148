import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addInventory } from "../actions/inventories";

const AddInventory = ({ addInventory, inventories }) => {
  const { t } = useTranslation();
  const [selectedImages, setSelectedImages] = useState([]);
  const [inventoryData, setInventoryData] = useState({
    productName: "",
    // price: null,
    quantity: null,
    userNum: "",
    user: "",
  });
  const [imgUrls, setImgUrls] = useState([]);
  const { productName, price, quantity, user, userNum } = inventoryData;
  const history = useHistory();
  const onChange = (e) => {
    setInventoryData({ ...inventoryData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    addInventory(inventoryData, selectedImages);
  };
  useEffect(() => {
    if (inventories.inventoryAdded) {
      setTimeout(() => history.push("/dashboard/inventories"), 2000);
    }
  }, [inventories.inventoryAdded]);
  const onFileChange = (e) => {
    // setInventoryData({ ...inventoryData, images: e.target.files });

    let fileArr = [];
    fileArr.push(e.target.files);
    Object.keys(e.target.files).forEach(function (key) {

      setSelectedImages((selectedImages) => [
        ...selectedImages,
        e.target.files[key],
      ]);
      setImgUrls((imgUrls) => [
        ...imgUrls,
        URL.createObjectURL(e.target.files[key]),
      ]);
    });

    // setInventoryData((inventoryData) => [
    //   ...inventoryData.images,
    //   e.target.files,
    // ]);
  };
  const removeImg = (el, i) => {

    setImgUrls(imgUrls.filter((e) => e !== el));
    setSelectedImages(selectedImages.filter((o) => o !== selectedImages[i]));
  };

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="row menu-row">
            <div className="col menu-col mr-2"></div>
          </div>
        </div>
        <div className="container login-container">
          <div className="row  login-row">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5 login-div ">
              <div className="text-center">
                <span className="chuna-zhurawa ">{t("add")}</span>
              </div>
              <form onSubmit={onSubmit} className="login-form">
                <div className="parent-input">
                  <input
                    onChange={onChange}
                    name="user"
                    value={user}
                    type="text"
                    className="form-control input-inline"
                    id="name"
                    aria-describedby="name"
                  />
                  <span className="inline-label-span">
                    {t("inventory_owner")}
                  </span>
                </div>
                <div className="parent-input">
                  <input
                    required
                    onChange={onChange}
                    name="userNum"
                    value={userNum}
                    type="text"
                    className="form-control input-inline"
                    id="name"
                    aria-describedby="name"
                  />
                  <span className="inline-label-span">{t("phone_number")}</span>
                </div>
                <div className="parent-input">
                  <input
                    onChange={onChange}
                    name="productName"
                    value={productName}
                    type="text"
                    className="form-control input-inline"
                    id="name"
                    aria-describedby="name"
                  />
                  <span className="inline-label-span">{t("item_type")}</span>
                </div>

                <div className="parent-input">
                  <input
                    required
                    min="1"
                    onChange={onChange}
                    type="number"
                    name="quantity"
                    value={quantity}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  />
                  <span className="inline-label-span">{t("quantity")}</span>
                </div>

                {/* <div className="parent-input">
                  <input
                    required
                    onChange={onChange}
                    type="number"
                    name="price"
                    value={price}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  />
                  <span className="inline-label-span"> نرخ</span>
                </div> */}

                <div className="parent-input">
                  <input
                    type="file"
                    multiple="multiple"
                    class="form-control-file input-inline"
                    id="exampleFormControlFile1"
                    onChange={onFileChange}
                  />
                  <span className="inline-label-span">{t("images")}</span>
                </div>
                <div className="parent-input">
                  <div className="row">
                    {imgUrls.length > 0 &&
                      imgUrls.map((el, i) => {
                        return (
                          <div key={i} className="col-2 w-25 m-3">
                            <i
                              onClick={() => removeImg(el, i)}
                              style={{ position: "absolute" }}
                              className="fas fa-times"
                            ></i>
                            <img
                              className="previewed-imgs"
                              src={el}
                              alt="selected imgs"
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>

                {inventories.pendingAddInventory && (
                  <div className="spinner-grow text-warning " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {inventories.inventoryAdded && (
                  <div className="alert alert-success" role="alert">
                    {t("successful")}
                  </div>
                )}

                {inventories.addingInventoryFailed && (
                  <div className="alert alert-danger" role="alert">
                    {t("failed")}
                  </div>
                )}
                <div className="container">
                  <div className="row text-center d-flex justify-content-center mt-5">
                    <button
                      type="submit"
                      className="btn login-button btn-primary"
                    >
                      {t("add")}
                    </button>
                    {/* <Link to="/login">
                      <p className="forgot-ps">وشەی نهێنیت بیر چۆتەوە ؟</p>
                    </Link> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  inventories: state.inventories,
});

export default connect(mapStateToProps, { addInventory })(AddInventory);
