import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// import { AddBranch } from "../actions/cityFees";
import { addBranch } from "../actions/branches";
import { getUserBranch } from "../utils/helpers";

const AddBranch = ({ addBranch, location, branches }) => {
  const { t } = useTranslation();
  const [branchData, setBranchData] = useState({
    branchName: "",
    commission: 0,
  });

  const { branchName, commission } = branchData;
  const history = useHistory();
  const onChange = (e) => {
    setBranchData({
      ...branchData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    addBranch(branchData);
  };
  //   useEffect(() => {
  //     if (branches.addBranch) {
  //       setTimeout(() => history.push("/dashboard/cities"), 2000);
  //     }
  //   }, [branches.addBranch]);

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="row menu-row">
            <div className="col menu-col mr-2"></div>
          </div>
        </div>
        <div className="container login-container">
          <div className="row  login-row">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5 login-div ">
              <div className="text-center">
                <span className="chuna-zhurawa ">{t("add")}</span>
              </div>
              <form onSubmit={onSubmit} className="login-form">
                <div className="parent-input">
                  <input
                    required
                    onChange={onChange}
                    type="text"
                    name="branchName"
                    value={branchName}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                    placeholder=""
                  />
                  <span className="inline-label-span">{t("name")}</span>
                </div>
                {getUserBranch() === "All" && (
                  <div className="parent-input">
                    <input
                      required
                      onChange={onChange}
                      type="number"
                      name="commission"
                      value={commission}
                      className="form-control input-inline"
                      id="exampleInputPassword1"
                      placeholder=""
                    />
                    <span className="inline-label-span">{t("commission")}</span>
                  </div>
                )}
                {branches.pendingAddBranch && (
                  <div className="spinner-grow text-warning " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}

                {branches.branchAdded && (
                  <div className="alert alert-success" role="alert">
                    {t("successful")}
                  </div>
                )}
                {branches.addBranchFailed && (
                  <div className="alert alert-danger" role="alert">
                    {t("failed")}
                  </div>
                )}
                <div className="container">
                  <div className="row text-center d-flex justify-content-center mt-5">
                    <button
                      type="submit"
                      className="btn login-button btn-primary"
                    >
                      {t("add")}
                    </button>
                    {/* <Link to="/login">
                      <p className="forgot-ps">وشەی نهێنیت بیر چۆتەوە ؟</p>
                    </Link> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  branches: state.branches,
});

export default connect(mapStateToProps, { addBranch })(AddBranch);
