import {
  ADD_BRANCH,
  ADD_BRANCH_FAILED,
  DELETE_BRANCH,
  DELETE_BRANCH_FAILED,
  GET_BRANCHES,
  GET_BRANCHES_FAILED,
  PENDING_ADD_BRANCH,
  PENDING_GET_BRANCHES,
  UPDATE_BRANCH,
  UPDATE_BRANCH_FAILED,
} from "../types/types";

const initialState = {
  pendingGetBranches: true,
  branches: [],
  getBranchesFailed: false,
  pendingAddBranch: false,
  branchAdded: false,
  addBranchFailed: false,
  deleteBranch: false,
  deleteBranchFailed: false,
  updateBranchFailed: false,
  updateBranch: false,
};

const branchesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PENDING_GET_BRANCHES:
      return {
        ...initialState,
      };
    case GET_BRANCHES:
      return {
        ...state,
        pendingGetBranches: false,
        branches: payload,
      };
    case GET_BRANCHES_FAILED:
      return {
        ...state,
        getBranchesFailed: true,
        pendingGetBranches: false,
      };
    case PENDING_ADD_BRANCH:
      return {
        ...state,
        pendingAddBranch: true,
        branchAdded: false,
        addBranchFailed: false,
      };
    case ADD_BRANCH:
      return {
        ...state,
        pendingAddBranch: false,
        branchAdded: true,
        addBranchFailed: false,
      };
    case ADD_BRANCH_FAILED:
      return {
        ...state,
        pendingAddBranch: false,
        branchAdded: false,
        addBranchFailed: true,
      };
    case DELETE_BRANCH:
      return {
        ...state,
        deleteBranch: true,
        deleteBranchFailed: false,
      };
    case DELETE_BRANCH_FAILED:
      return {
        ...state,
        deleteBranch: false,
        deleteBranchFailed: true,
      };
    case UPDATE_BRANCH:
      return {
        ...state,
        branchUpdated: true,
        updateBranchFailed: false,
      };
    case UPDATE_BRANCH_FAILED:
      return {
        ...state,
        branchUpdated: false,
        updateBranchFailed: false,
      };

    default:
      return state;
  }
};

export default branchesReducer;
