import {
  DELETE_REPORT,
  DELETE_REPORT_FAILED,
  GET_REPORTS,
  GET_REPORTS_FAILED,
  PENDING_GET_REPORTS,
} from "../types/types";

const initialState = {
  pendingGetReports: false,
  reports: [],
  getReportsFailed: false,
  deleteReport: false,
  deleteReportFailed: false,
};
export default function reports(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PENDING_GET_REPORTS:
      return {
        ...state,
        pendingGetReports: true,
        reports: [],
        getReportsFailed: false,
        deleteReport: false,
        deleteReportFailed: false,
      };
    case GET_REPORTS:
      return {
        ...state,
        pendingGetReports: false,
        reports: payload,
        getReportsFailed: false,
        deleteReport: false,
        deleteReportFailed: false,
      };
    case GET_REPORTS_FAILED:
      return {
        ...state,
        pendingGetReports: false,
        reports: [],
        getReportsFailed: true,
        deleteReport: false,
        deleteReportFailed: false,
      };
    case DELETE_REPORT:
      return {
        ...state,
        deleteReport: true,
        deleteReportFailed: false,
      };
    case DELETE_REPORT_FAILED:
      return {
        ...state,
        deleteReport: false,
        deleteReportFailed: true,
      };
    default:
      return state;
  }
}
