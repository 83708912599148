import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateInventory } from "../actions/inventories";

const EditInventory = ({ updateInventory, inventories, location }) => {
  const { t } = useTranslation();
  const [selectedImages, setSelectedImages] = useState([]);
  const [inventoryData, setInventoryData] = useState({
    productName: location.state.el.productName,
    price: location.state.el.price,
    quantity: location.state.el.quantity,
    userNum: "",
    user: "",
    id: location.state.el._id,
  });

  const { productName, price, quantity, user, userNum } = inventoryData;
  const history = useHistory();
  const onChange = (e) => {
    setInventoryData({ ...inventoryData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateInventory(inventoryData, selectedImages);
  };
  useEffect(() => {
    if (inventories.inventoryUpdated) {
      setTimeout(() => history.push("/dashboard/inventories"), 2000);
    }
  }, [inventories.inventoryUpdated]);
  const onFileChange = (e) => {
    // setInventoryData({ ...inventoryData, images: e.target.files });
    setSelectedImages(e.target.files);

    // setInventoryData((inventoryData) => [
    //   ...inventoryData.images,
    //   e.target.files,
    // ]);
  };

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="row menu-row">
            <div className="col menu-col mr-2"></div>
          </div>
        </div>
        <div className="container login-container">
          <div className="row  login-row">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5 login-div ">
              <div className="text-center">
                <span className="chuna-zhurawa ">{t("edit")}</span>
              </div>
              <form onSubmit={onSubmit} className="login-form">
                <div className="parent-input">
                  <input
                    onChange={onChange}
                    name="productName"
                    value={productName}
                    type="text"
                    className="form-control input-inline"
                    id="name"
                    aria-describedby="name"
                  />
                  <span className="inline-label-span">{t("item_type")}</span>
                </div>

                <div className="parent-input">
                  <input
                    onChange={onChange}
                    type="number"
                    name="quantity"
                    value={quantity}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                  />
                  <span className="inline-label-span">{t("quantity")}</span>
                </div>

                {/* <div className="parent-input">
                  <input
                    onChange={onChange}
                    type="number"
                    name="price"
                    value={price}
                    className="form-control input-inline"
                    id="exampleInputPassword1"
                  />
                  <span className="inline-label-span"> نرخ</span>
                </div> */}

                <div className="parent-input">
                  <input
                    type="file"
                    multiple="multiple"
                    class="form-control-file input-inline"
                    id="exampleFormControlFile1"
                    onChange={onFileChange}
                  />
                  <span className="inline-label-span">{t("images")}</span>
                </div>

                {inventories.pendingUpdateInventory && (
                  <div className="spinner-grow text-warning " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {inventories.inventoryUpdated && (
                  <div className="alert alert-success" role="alert">
                    {t("successful")}
                  </div>
                )}

                {inventories.updatingInventoryFailed && (
                  <div className="alert alert-danger" role="alert">
                    {t("failed")}
                  </div>
                )}
                <div className="container">
                  <div className="row text-center d-flex justify-content-center mt-5">
                    <button
                      type="submit"
                      className="btn login-button btn-primary"
                    >
                      {t("edit")}
                    </button>
                    {/* <Link to="/login">
                      <p className="forgot-ps">وشەی نهێنیت بیر چۆتەوە ؟</p>
                    </Link> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  inventories: state.inventories,
});

export default connect(mapStateToProps, { updateInventory })(EditInventory);
