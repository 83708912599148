import {
  GET_CITY_FEES,
  GET_CITIES_FAILED,
  PENDING_GET_CITY_FEES,
  ADD_CITY_FEE,
  PENDING_ADD_CITY_FEE,
  ADD_CITY_FEE_FAILED,
  PENDING_UPDATE_CITY_FEE,
  UPDATE_CITY_FEE,
  UPDATE_CITY_FEE_FAILED,
  DELETE_CITY_FEE,
  DELETE_CITY_FEE_FAILED,
} from "../types/types";

const initialState = {
  pendingGetFees: false,
  cityFees: [],
  getCityFeesFailed: false,
  pendingAddingCityFee: false,
  cityFeeAdded: false,
  addingCityFeeFailed: false,
  pendingCityFeeUpdate: false,
  cityFeeUpdated: false,
  updateCityFeeFailed: false,
  deleteCityFee: false,
  deleteCityFeeFailed: false,
};

export default function getCityFees(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case PENDING_GET_CITY_FEES:
      return {
        ...state,
        cityFees: [],
        pendingGetFees: true,
        pendingAddingCityFee: false,
        cityFeeAdded: false,
        addingCityFeeFailed: false,
        getCityFeesFailed: false,
        pendingCityFeeUpdate: false,
        cityFeeUpdated: false,
        updateCityFeeFailed: false,
        deleteCityFee: false,
        deleteCityFeeFailed: false,
      };
    case GET_CITY_FEES:
      return {
        ...state,
        pendingGetFees: false,
        cityFees: payload,
        getCityFeesFailed: false,
        pendingAddingCityFee: false,
        cityFeeAdded: false,
        addingCityFeeFailed: false,
        pendingCityFeeUpdate: false,
        cityFeeUpdated: false,
        updateCityFeeFailed: false,
      };
    case GET_CITIES_FAILED:
      return {
        ...state,
        pendingGetFees: false,

        getCityFeesFailed: true,
      };
    case PENDING_ADD_CITY_FEE:
      return {
        ...state,
        pendingAddingCityFee: true,
        cityFeeAdded: false,
        addingCityFeeFailed: false,
      };
    case ADD_CITY_FEE:
      return {
        ...state,

        pendingAddingCityFee: false,
        cityFeeAdded: true,
        addingCityFeeFailed: false,
      };
    case ADD_CITY_FEE_FAILED:
      return {
        ...state,
        pendingAddingCityFee: false,
        cityFeeAdded: false,
        addingCityFeeFailed: true,
      };
    case PENDING_UPDATE_CITY_FEE:
      return {
        ...state,
        pendingCityFeeUpdate: true,
        cityFeeUpdated: false,
        updateCityFeeFailed: false,
      };
    case UPDATE_CITY_FEE:
      return {
        ...state,
        pendingCityFeeUpdate: false,
        cityFeeUpdated: true,
        updateCityFeeFailed: false,
      };
    case UPDATE_CITY_FEE_FAILED:
      return {
        ...state,
        pendingCityFeeUpdate: false,
        cityFeeUpdated: false,
        updateCityFeeFailed: true,
      };
    case DELETE_CITY_FEE:
      return {
        ...state,
        deleteCityFee: true,
        deleteCityFeeFailed: false,
      };
    case DELETE_CITY_FEE_FAILED:
      return {
        ...state,
        deleteCityFee: false,
        deleteCityFeeFailed: true,
      };
    default:
      return state;
  }
}
