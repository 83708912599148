import {createStore, applyMiddleware, compose} from "redux"

import thunk from "redux-thunk";
import { announcementApi } from "./pages/announcements/announcementQueries";
import rootReducer from "./reducers";

const initialState = {};
const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware, announcementApi.middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;