import { t } from "i18next";
import React from "react";
import { useRequestResetMutation } from "../actions/rtquery-services";

export const RequestResetPassword = () => {
  const [email, setEmail] = React.useState("");

  const [requestReset, result] = useRequestResetMutation();
  return (
    <section className="login-section">
      <div className="container login-container">
        <div className="row  login-row">
          <div className="col-12 col-md-8 col-lg-6 col-xl-4 login-div ">
            <span className="chuna-zhurawa">{t("email")}</span>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await requestReset(email);
                result.reset();
              }}
              className="login-form"
            >
              <div className="form-group">
                <div className="input-group-prepend">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    value={email}
                    type="email"
                    className="form-control input-login"
                    id="name"
                    aria-describedby="name"
                    placeholder={t("email")}
                  />
                  <div className="input-group-text">
                    <i className="far user-input-icon fa-user"></i>
                  </div>
                </div>
                <div className="row form-footer">
                  <button
                    type="submit"
                    className="btn login-button btn-primary"
                  >
                    {t("continue")}
                  </button>
                </div>
              </div>
              {result?.data && (
                <div className="alert alert-success mt-3" role="alert">
                  {t("successful")}
                </div>
              )}
              {result?.isError && (
                <div className="alert alert-danger mt-3" role="alert">
                  {t("failed")}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
