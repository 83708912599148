import {
  ADD_NEGHBORHOOD,
  ADD_NEIGHBORHOOD_FAILED,
  DELETE_NEIGHBORHOOD,
  DELETE_NEIGHBORHOOD_FAILED,
  GET_NEIGHBORHOODS,
  GET_NEIGHBORHOODS_FAILED,
  NEIGHBORHOOD_UPDATED,
  PENDING_GET_NEIGHBORHOODS,
  UPDATE_NEIGHBORHOOD_FAILED,
} from "../types/types";

const initialState = {
  pendingGetNeighborhoods: false,
  neighborhoods: [],
  getNeighborhoodsFailed: false,
  addNeighborhood: false,
  addNeighborhoodFailed: false,
  deleteNeighborhood: false,
  deleteNeighborhoodFailed: false,
  neighborhoodUpdated: false,
  updateNeighborhoodFailed: false,
};

export default function neighborhoods(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case PENDING_GET_NEIGHBORHOODS:
      return {
        ...state,
        pendingGetNeighborhoods: true,
        neighborhoods: [],
        getNeighborhoodsFailed: false,
        addNeighborhood: false,
        addNeighborhoodFailed: false,
        deleteNeighborhood: false,
        deleteNeighborhoodFailed: false,
        neighborhoodUpdated: false,
        updateNeighborhoodFailed: false,
      };
    case GET_NEIGHBORHOODS:
      return {
        ...state,
        pendingGetNeighborhoods: false,
        neighborhoods: payload,
        getNeighborhoodsFailed: false,
        addNeighborhood: false,
        addNeighborhoodFailed: false,
        deleteNeighborhood: false,
        deleteNeighborhoodFailed: false,
      };
    case GET_NEIGHBORHOODS_FAILED:
      return {
        ...state,
        pendingGetNeighborhoods: false,
        neighborhoods: [],
        getNeighborhoodsFailed: true,
        addNeighborhood: false,
        addNeighborhoodFailed: false,
        deleteNeighborhood: false,
        deleteNeighborhoodFailed: false,
      };
    case ADD_NEGHBORHOOD:
      return {
        ...state,
        pendingGetNeighborhoods: false,
        neighborhoods: [],
        getNeighborhoodsFailed: false,
        addNeighborhood: true,
        addNeighborhoodFailed: false,
        deleteNeighborhood: false,
        deleteNeighborhoodFailed: false,
      };
    case ADD_NEIGHBORHOOD_FAILED:
      return {
        ...state,
        pendingGetNeighborhoods: false,
        neighborhoods: [],
        getNeighborhoodsFailed: false,
        addNeighborhood: false,
        addNeighborhoodFailed: true,
        deleteNeighborhood: false,
        deleteNeighborhoodFailed: false,
      };
    case NEIGHBORHOOD_UPDATED:
      return {
        ...state,

        neighborhoodUpdated: true,
        updateNeighborhoodFailed: false,
      };
    case UPDATE_NEIGHBORHOOD_FAILED:
      return {
        ...state,
        neighborhoodUpdated: false,
        updateNeighborhoodFailed: true,
      };
    case DELETE_NEIGHBORHOOD:
      return {
        ...state,
        deleteNeighborhood: true,
        deleteNeighborhoodFailed: false,
      };
    case DELETE_NEIGHBORHOOD_FAILED:
      return {
        ...state,
        deleteNeighborhood: false,
        deleteNeighborhoodFailed: true,
      };
    default:
      return state;
  }
}
