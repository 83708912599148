import {
  ADD_DRIVER,
  ADD_DRIVER_FAILED,
  EDIT_DRIVER,
  EDIT_DRIVER_FAILED,
  GET_DRIVERS,
  GET_DRIVERS_FAILED,
  DELETE_DRIVER,
  DELETE_DRIVER_ORDER,
  DELETE_DRIVER_FAILED,
  PENDING_ADDING_DRIVER,
  PENDING_EDIT_DRIVER,
} from "../types/types";

const initialState = {
  drivers: [],
  pending: true,
  failed: false,
  pendingAdd: false,
  driverAdded: false,
  driverAddedFailed: false,
  driverEdited: false,
  pendingEdit: false,
  editFailed: false,
  deleted: false,
  deleteMsg: null,
  orderDeleted: false,
};

export default function drivers(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_DRIVERS:
      return {
        ...state,
        drivers: payload,
        pending: false,
        failed: false,
        driverAdded: false,
        deleted: false,
        deleteMsg: "",

        orderDeleted: false,
        pendingAdd: false,
        driverEdited: false,
        driverAddedFailed: false,
      };
    case GET_DRIVERS_FAILED:
      return {
        ...state,
        failed: true,
        pending: false,
        drivers: [],
      };
    case ADD_DRIVER:
      return {
        ...state,
        failed: false,
        pending: false,
        driverAdded: true,
        driverAddedFailed: false,
        pendingAdd: false,
        deleteMsg: null,
      };
    case ADD_DRIVER_FAILED:
      return {
        ...state,
        driverAdded: false,
        driverAddedFailed: true,
        deleteMsg: payload,
        pendingAdd: false,
      };
    case PENDING_EDIT_DRIVER:
      return {
        ...state,
        pendingEdit: true,
        driverEdited: false,
        editFailed: false,
      };
    case EDIT_DRIVER:
      return {
        ...state,
        pendingEdit: false,
        driverEdited: true,
        editFailed: false,
      };
    case EDIT_DRIVER_FAILED:
      return {
        ...state,
        pendingEdit: false,

        driverEdited: false,
        editFailed: true,
      };
    case DELETE_DRIVER:
      return {
        ...state,
        deleted: true,
      };
    case DELETE_DRIVER_FAILED:
      return {
        ...state,

        deleted: false,
      };

    case DELETE_DRIVER_ORDER:
      return {
        ...state,
        orderDeleted: true,
      };
    case PENDING_ADDING_DRIVER:
      return {
        ...state,
        pendingAdd: true,
        driverAddedFailed: false,
        driverAdded: false,
      };
    default:
      return state;
  }
}
