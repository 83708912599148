import api from "../api/api";

import {
  PENDING_GET_INVENTORIES,
  GET_INVENTORIES,
  GET_INVENTORIES_FAILED,
  PENDING_ADD_INVENTORY,
  ADD_INVENTORY,
  ADD_INVENTORY_FAILED,
  PENDING_UPDATE_INVENTORY,
  UPDATE_INVENTORY,
  UPDATE_INVENTORY_FAILED,
} from "../types/types";

export const getInventories = (searchedVal) => async (dispatch) => {
  dispatch({
    type: PENDING_GET_INVENTORIES,
  });
  try {
    const res = await api.get(`/inventories?searchKey=${searchedVal}`);
    dispatch({
      type: GET_INVENTORIES,
      payload: res.data.inventories,
    });
  } catch (e) {
    dispatch({
      type: GET_INVENTORIES_FAILED,
    });
  }
};
export const addInventory = (inventoryData, images) => async (dispatch) => {
  dispatch({
    type: PENDING_ADD_INVENTORY,
  });

  try {
    const data = new FormData();
    for (let i = 0; i < images.length; i++) {
      data.append("image", images[i]);
    }
    
    data.append("productName", inventoryData.productName);
    data.append("quantity", Number(inventoryData.quantity));
    // data.append("price", Number(inventoryData.price));
    data.append("user", inventoryData.user);
    data.append("userNum", inventoryData.userNum);
   
    const res = await api.post("/inventories", data);

    dispatch({
      type: ADD_INVENTORY,
      payload: res.data.inventory,
    });
  } catch (e) {
    dispatch({
      type: ADD_INVENTORY_FAILED,
    });
  }
};
export const updateInventory = (inventoryData, images) => async (dispatch) => {
  const { id } = inventoryData;
  dispatch({
    type: PENDING_UPDATE_INVENTORY,
  });

  try {
    const data = new FormData();
    for (let i = 0; i < images.length; i++) {
      data.append("image", images[i]);
    }

    data.append("productName", inventoryData.productName);
    data.append("quantity", Number(inventoryData.quantity));
    // data.append("price", Number(inventoryData.price));
    
    const res = await api.patch(`/inventories/${id}`, data);
    dispatch({
      type: UPDATE_INVENTORY,
      payload: res.data.inventory,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_INVENTORY_FAILED,
      payload: e.message,
    });
  }
};
