import api from "../api/api";
import {
  POST_LOGIN,
  POST_LOGOUT,
  LOGIN_FAILED,
  PENDING_LOGIN,
} from "../types/types";

export const login = (name, password) => async (dispatch) => {
  dispatch({
    type: PENDING_LOGIN,
  });
  try {
    const res = await api.post("/admin/login", {
      name: name,
      password: password,
    });
    dispatch({
      type: POST_LOGIN,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: LOGIN_FAILED,
      payload: e.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();

    dispatch({
      type: POST_LOGOUT,
      payload: null,
    });
  } catch (e) {
    console.log(e);
  }
};
