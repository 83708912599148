import {
  ADD_ORDER,
  ADD_ORDER_FAILED,
  ADD_SENDER,
  ADD_SENDER_FAILED,
  DELETE_SENDER,
  DELETE_SENDER_FAILED,
  DELETE_SENDER_ORDER,
  DELETE_SENDER_ORDER_FAILED,
  EDIT_SENDER,
  EDIT_SENDER_FAILED,
  GET_SENDERS,
  GET_SENDERS_FAILED,
  GET_SENDER_ORDERS,
  PENDING_ADD_SENDER,
  PENDING_EDIT_SENDER,
  PENDING_GET_SENDERS,
  PENDING_ORDER,
  PENDING_UPDATE_ORDER,
  UPDATE_ORDER,
  UPDATE_ORDER_FAILED,
} from "../types/types";

const initialState = {
  senders: [],
  order: null,
  pending: true,
  failed: false,
  pendingAdd: false,
  userAdded: false,
  userAddedFailed: false,
  pendingOrder: false,
  orderAdded: false,
  addOrderFailed: false,
  senderEdited: false,
  senderDeleted: false,
  senderDeletedFailed: false,
  editFailed: false,
  deleted: false,
  deletedFailed: false,
};

export default function senders(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case PENDING_GET_SENDERS:
      return {
        ...state,
        pending: true,
        failed: false,
        senders: [],
        pendingEdit: false,
        order: null,
        orderUpdated: false,
        orderUpdateFailed: false,
      };
    case GET_SENDERS:
      return {
        ...state,
        order: null,
        senders: payload,
        pending: false,
        failed: false,
        userAdded: false,
        deleted: false,
        deletedFailed: false,
        pendingOrder: false,
        orderAdded: false,
        addOrderFailed: false,
        senderDeleted: false,
        pendingAdd: false,
        userAddedFailed: false,
        senderEdited: false,
        senderDeletedFailed: false,
        pendingUpdate: false,
        orderUpdated: false,
        orderUpdateFailed: false,
      };
    case GET_SENDERS_FAILED:
      return {
        ...state,
        failed: true,
        pending: false,
        senders: [],
        orderAdded: false,
      };
    case PENDING_ADD_SENDER:
      return {
        ...state,
        pendingAdd: true,
        userAdded: false,
        userAddedFailed: false,
      };
    case ADD_SENDER:
      return {
        ...state,
        pendingAdd: false,
        userAdded: true,
        userAddedFailed: false,
      };
    case ADD_SENDER_FAILED:
      return {
        ...state,
        pendingAdd: false,
        userAdded: false,
        userAddedFailed: true,
      };
    case PENDING_ORDER:
      return {
        ...state,
        pendingOrder: true,
        orderAdded: false,
        addOrderFailed: false,
        order: null,
      };
    case ADD_ORDER:
      return {
        ...state,
        pendingOrder: false,
        orderAdded: true,
        addOrderFailed: false,
        order: payload,
      };
    case ADD_ORDER_FAILED:
      return {
        ...state,
        pendingOrder: false,
        orderAdded: false,
        addOrderFailed: true,
      };
    case PENDING_EDIT_SENDER:
      return {
        ...state,
        pendingEdit: true,
        senderEdited: false,
        senderDeletedFailed: false,
      };
    case EDIT_SENDER:
      return {
        ...state,
        senderEdited: true,
        editFailed: false,
        pendingEdit: false,
      };
    case EDIT_SENDER_FAILED:
      return {
        ...state,
        senderEdited: false,
        editFailed: true,
        pendingEdit: false,
      };
    case DELETE_SENDER_ORDER:
      return {
        ...state,
        deleted: true,
        deletedFailed: false,
      };
    case DELETE_SENDER_ORDER_FAILED:
      return {
        ...state,
        deleted: false,
        deletedFailed: true,
      };
    case GET_SENDER_ORDERS:
      return {
        ...state,
        orderAdded: false,
      };
    case DELETE_SENDER:
      return {
        ...state,
        senderDeleted: true,
        senderDeletedFailed: false,
      };
    case DELETE_SENDER_FAILED:
      return {
        ...state,
        senderDeleted: false,
        senderDeletedFailed: true,
      };
    case PENDING_UPDATE_ORDER:
      return {
        ...state,
        pendingUpdate: true,
        orderUpdated: false,
        orderUpdateFailed: false,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        pendingUpdate: false,
        orderUpdated: true,
        orderUpdateFailed: false,
      };
    case UPDATE_ORDER_FAILED:
      return {
        ...state,
        pendingUpdate: false,
        orderUpdated: false,
        orderUpdateFailed: true,
      };
    default:
      return state;
  }
}
