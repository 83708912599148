import { GET_PROVINCES_FAILED, GET_PROVINCES } from "../types/types";

const initialState = {
  provinces: [],
  failed: false,
};

export default function getProvinces(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_PROVINCES:
      return {
        ...state,
        provinces: payload,
        failed: false,
      };
    case GET_PROVINCES_FAILED:
      return {
        ...state,
        provinces: [],
        failed: true,
      };
    default:
      return state;
  }
}
