import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import senderReducer from "./senderReducer";
import driverReducer from "./driverReducer";
import citiesReducer from "./citiesReducer";
import provincesReducer from "./provincesReducer";
import ordersReducer from "./ordersReducer";
import cityFeesReducer from "./cityFeesReducer";
import inventoriesReducer from "./inventoriesReducer";
import rulesReducer from "./rulesReducer";
import adminsReducer from "./adminsReducer";
import reportsReducer from "./reportsReducer";
import historiesReducer from "./historiesReducer";
import neighborhoodsReducer from "./neighborhoodsReducer";
import specialRequestsReducer from "./specialRequestsReducer";
import branchesReducer from "./branchesReducer";
import { announcementApi } from "../pages/announcements/announcementQueries";
import { branchApi } from "../actions/rtquery-services";

export default combineReducers({
  login: loginReducer,
  senders: senderReducer,
  drivers: driverReducer,
  cities: citiesReducer,
  provinces: provincesReducer,
  orders: ordersReducer,
  cityFees: cityFeesReducer,
  inventories: inventoriesReducer,
  admins: adminsReducer,
  rules: rulesReducer,
  [announcementApi.reducerPath]: announcementApi.reducer,
  [branchApi.reducerPath]: branchApi.reducer,
  reports: reportsReducer,
  histories: historiesReducer,
  neighborhoods: neighborhoodsReducer,
  specialRequests: specialRequestsReducer,
  branches: branchesReducer,
});
