import axios from "axios";

export const server = "https://api.sardam.potan.co";
export const localhost = "http://localhost:4000";

const api = axios.create({
  baseURL: server,
});
// Set the AUTH token for any request
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  const branch = localStorage.getItem("branch");
  config.headers.Authorization = token ? `Bearer ${token}` : null;
  config.headers.branch = branch?.toString();
  return config;
});

export default api;
