import api from "../api/api";
import {
  ADD_CITY_FEE,
  ADD_CITY_FEE_FAILED,
  GET_CITY_FEES,
  GET_CITY_FEES_FAILED,
  PENDING_ADD_CITY_FEE,
  PENDING_GET_CITY_FEES,
  PENDING_UPDATE_CITY_FEE,
  UPDATE_CITY_FEE,
  UPDATE_CITY_FEE_FAILED,
} from "../types/types";

export const getCityFees = (props) => async (dispatch) => {
  dispatch({
    type: PENDING_GET_CITY_FEES,
  });
  try {
    const res = await api.get("/cityfees?own=" + props?.own);
    dispatch({
      type: GET_CITY_FEES,
      payload: res.data.cityFees,
    });
  } catch (e) {
    dispatch({
      type: GET_CITY_FEES_FAILED,
    });
  }
};
export const addCityFee = (data) => async (dispatch) => {
  dispatch({
    type: PENDING_ADD_CITY_FEE,
  });
  try {
    const res = await api.post("/cityfees", data);
    dispatch({
      type: ADD_CITY_FEE,
      payload: res.data.cityFees,
    });
  } catch (e) {
    dispatch({
      type: ADD_CITY_FEE_FAILED,
    });
  }
};
export const updateCityFee = (data, id) => async (dispatch) => {
  dispatch({
    type: PENDING_UPDATE_CITY_FEE,
  });
  try {
    const res = await api.patch(`/cityfees/${id}`, data);
    dispatch({
      type: UPDATE_CITY_FEE,
      payload: res.data.cityFee,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_CITY_FEE_FAILED,
    });
  }
};
