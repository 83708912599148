import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../actions/rtquery-services";

export const ResetPassword = ({ history }) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState({
    password: "",
    confirmPassword: "",
  });

  const [resetPassword, result] = useResetPasswordMutation();
  const params = useParams();

  const { password, confirmPassword } = data;
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (result.isSuccess) {
      history.push("/login");
    }
  }, [result.isSuccess]);
  return (
    <section className="login-section">
      <div className="container login-container">
        <div className="row  login-row">
          <div className="col-12 col-lg-6 login-div ">
            <span className="chuna-zhurawa">{t("reset-password")}</span>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                resetPassword({
                  password,
                  token: params.token,
                  id: params.id,
                });
              }}
              className="login-form"
            >
              <div className="parent-input">
                <input
                  required
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  className="form-control input-inline"
                  id="exampleInputPassword1"
                  placeholder=""
                />
                <span className="inline-label-span"> {t("password")}</span>
              </div>
              <div className="parent-input">
                <input
                  required
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChange}
                  className="form-control input-inline"
                  id="exampleInputPassword1"
                  placeholder=""
                />
                <span className="inline-label-span">{t("reenter")}</span>
              </div>
              <div className="row form-footer">
                <button type="submit" className="btn login-button btn-primary">
                  {t("continue")}
                </button>
              </div>
              {result.isSuccess && (
                <div className="alert alert-success mt-3" role="alert">
                  {t("successful")}
                </div>
              )}
              {result.isError && (
                <div className="alert alert-danger mt-3" role="alert">
                  {t("failed")}
                </div>
              )}
              {confirmPassword !== password && (
                <div className="alert alert-danger mt-3" role="alert">
                  {t("passwords_do_not_match")}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
