import {
  POST_LOGIN,
  POST_LOGOUT,
  PENDING_LOGIN,
  LOGIN_FAILED,
} from "../types/types";

const initialState = {
  user: [],
  failed: false,
  loading: false,
};

export default function login(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case PENDING_LOGIN:
      return {
        ...state,
        pending: true,
      };
    case POST_LOGIN:
      localStorage.setItem("token", payload.token);
      localStorage.setItem("branch", payload.admin.branch?.branchName);
      localStorage.setItem("userBranch", payload.admin.branch?.branchName);
      localStorage.setItem("role", payload.admin.role);
      return {
        ...state,
        user: payload.admin,
        failed: false,
        pending: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        failed: true,
        pending: false,
      };
    case POST_LOGOUT:
      return {
        ...state,
        user: [],
        failed: false,
      };
    default:
      return state;
  }
}
