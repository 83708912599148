import api from "../api/api";
import {
  ADD_NEGHBORHOOD,
  DELETE_NEIGHBORHOOD,
  GET_NEIGHBORHOODS,
  GET_NEIGHBORHOODS_FAILED,
  NEIGHBORHOOD_UPDATED,
  PENDING_GET_NEIGHBORHOODS,
  UPDATE_NEIGHBORHOOD_FAILED,
} from "../types/types";

export const getNeighborhoods = () => async (dispatch) => {
  dispatch({
    type: PENDING_GET_NEIGHBORHOODS,
  });

  try {
    const res = await api.get("/neighborhoods");
    dispatch({
      type: GET_NEIGHBORHOODS,
      payload: res.data.neighborhood,
    });
  } catch (e) {
    alert(e.response.data.message);
    dispatch({
      type: GET_NEIGHBORHOODS_FAILED,
    });
  }
};
export const addNiehgborhood = (data) => async (dispatch) => {
  const { name, cityId } = data;
  try {
    await api.post("/neighborhoods", {
      name,
      cityId,
    });
    dispatch({
      type: ADD_NEGHBORHOOD,
    });
  } catch (e) {
    alert(e.response.data.message);
    dispatch({
      type: DELETE_NEIGHBORHOOD,
    });
  }
};
export const getNeighborhoodsByCityId = (id) => async (dispatch) => {
  dispatch({
    type: PENDING_GET_NEIGHBORHOODS,
  });
  try {
    const res = await api.get(`/neighborhoods/city/${id}`);
    dispatch({
      type: GET_NEIGHBORHOODS,
      payload: res.data.neighborhoods,
    });
    return res.data;
  } catch (e) {
    alert(e.response.data.message);
    dispatch({
      type: GET_NEIGHBORHOODS_FAILED,
    });
  }
};
export const updateNeighborhood = (data, id) => async (dispatch) => {
  try {
    const res = await api.patch(`/neighborhoods/${id}`, {
      name: data.name,
      cityId: data.cityId,
    });
    dispatch({
      type: NEIGHBORHOOD_UPDATED,
      payload: res.data.neighborhoods,
    });
  } catch (e) {
    alert(e.response.data.message);
    dispatch({
      type: UPDATE_NEIGHBORHOOD_FAILED,
    });
  }
};
