import React, { useEffect, Suspense, useState } from "react";

import "../App.css";
import { useTranslation } from "react-i18next";
import {
  useGetBranchQuery,
  useGetOrdersByBranchQuery,
  useReceiveCommissionMutation,
  useUpdateOrderBranchMutation,
} from "../actions/rtquery-services";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Confirm, CustomDialog } from "react-st-modal";
import { deleteItem } from "../actions/deleteDoc";
import Moment from "react-moment";
import { getUserBranch, getUserRole } from "../utils/helpers";
const Table = React.lazy(() => import("../components/Table"));
// const AddOrder = React.lazy(() => import("../components/AddOrder"));

const BranchDetails = () => {
  const [searchedVal, setSearchedVal] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation();
  const params = useParams();
  const {
    data,
    isLoading,
    refetch: refetchStatus,
  } = useGetBranchQuery(params.id, {
    refetchOnMountOrArgChange: true,
  });

  const [updateOrderBranch, result] = useUpdateOrderBranchMutation();

  const {
    data: orders,
    isLoading: isLoadingOrders,
    refetch,
  } = useGetOrdersByBranchQuery(
    { id: params.id, barCode: searchedVal },
    { refetchOnMountOrArgChange: true }
  );

  const [receiveCommission, receiveResult] = useReceiveCommissionMutation();
  const onSubmit = async (e) => {
    e.preventDefault();
    // getOrders(null, null, null, searchedVal);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (result.isSuccess) {
      refetch();
      refetchStatus();
      result.reset();
    }
  }, [result.isSuccess]);

  useEffect(() => {
    if (receiveResult.isSuccess) {
      refetch();
      refetchStatus();
      receiveResult.reset();
    }
  }, [receiveResult.isSuccess]);

  useEffect(() => {
    if (result.error) {
      alert(result.error.data.message);
      result.reset();
    }
  }, [result.error]);

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    orders?.orders?.length > 0 &&
    orders.orders.slice(offset, offset + PER_PAGE).map((el) => {
      let kurdish2 = [
        el.status === "received" && t("received"),
        el.status === "prepared" && t("prepared"),
        el.status === "delivered" && t("delivered"),
        el.status === "somethingWasWrong" && t("hasIssue"),
        el.status === "returned" && t("returned"),
        el.status === "givenBack" && t("givenBack"),
      ];
      let date = el.createdAt;
      return (
        <Table key={el._id}>
          <tbody>
            <tr>
              <td className="icons-td-desktop">
                {getUserBranch() === el.senderBranch.branchName &&
                  getUserRole() === "admin" && (
                    <button
                      className=""
                      style={{
                        backgroundColor: el.branchDebtPaid ? "grey" : "#2533AC",
                        color: "white",
                        padding: "5px",
                        textDecoration: "none",
                        border: "none",
                        borderRadius: "5px",
                      }}
                      disabled={el.branchDebtPaid}
                      onClick={async () => {
                        if (el.branchDebtPaid) return;
                        const result = await Confirm(
                          t("are_you_sure_about_this"),
                          "",
                          t("yes"),
                          t("no")
                        );

                        if (result) {
                          updateOrderBranch(el._id);
                        } else {
                          // Сonfirmation not confirmed
                        }
                      }}
                    >
                      {t("receive")}
                    </button>
                  )}
              </td>
              <td className="icons-td-desktop">
                {getUserBranch() === "All" &&
                el.senderBranch._id.toString() === params.id ? (
                  <button
                    className=""
                    style={{
                      backgroundColor: el.mainBranchComCollected
                        ? "grey"
                        : "#2533AC",
                      color: "white",
                      padding: "5px",
                      textDecoration: "none",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    disabled={el.mainBranchComCollected}
                    onClick={async () => {
                      if (el.mainBranchComCollected) return;
                      const result = await Confirm(
                        t("are_you_sure_about_this"),
                        "",
                        t("yes"),
                        t("no")
                      );

                      if (result) {
                        receiveCommission(el._id);
                      } else {
                        // Сonfirmation not confirmed
                      }
                    }}
                  >
                    {el.mainBranchComCollected
                      ? t("received")
                      : t("receive_commission")}
                  </button>
                ) : (
                  "_____"
                )}
              </td>

              <td>
                <Link
                  to={{
                    pathname: `/dashboard/histories/${el._id}`,
                  }}
                >
                  {t("view")}
                </Link>
              </td>
              <td>
                <Moment format="YYYY/MM/DD">{date}</Moment>
              </td>

              <td> {el.driverId ? el.driverId.fullName : t("none")}</td>
              <td> {el.senderId && el.senderId.fullName}</td>
              <td> {kurdish2}</td>
              <td> {el.totalPrice}</td>
              <td>{el.reciverId?.fullName}</td>
              <td>{"0" + el.reciverId?.phoneNumber.substring(4)}</td>
              <td className="prettyId-font">{el.prettyId}</td>
            </tr>
          </tbody>
        </Table>
      );
    });
  const pageCount = Math.ceil(
    orders?.orders?.length > 0 && orders.orders.length / PER_PAGE
  );

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const onChange = (e) => {
    setSearchedVal(e.target.value);
  };

  return (
    <>
      <section className="sender-section">
        <div className="container">
          <div className="row text-center d-flex justify-content-end mb-4 mt-lg-5">
            <div className="d-flex justify-content-end align-items-center w-100 px-5 mt-5">
              <h3 className="mr-2 fs-1">{data?.branchStatus?.debt}</h3>
              <h3 className="fs-1">: {t("debt")} </h3>
              <h3 className="mr-2 fs-1 ml-5">{data?.branchStatus?.myDebt}</h3>
              <h3 className="fs-1">: {t("my_debt")} </h3>

              {data?.branch?.branchName === "All" && (
                <>
                  <h3 className="mr-2 fs-1 ml-5">
                    {data?.branchStatus?.myMainBranchDebt}
                  </h3>
                  <h3 className="fs-1">: {t("commission_debt")} </h3>
                </>
              )}
              {getUserBranch() === "All" && (
                <>
                  <h3 className="mr-2 fs-1 ml-5">
                    {data?.branchStatus?.mainCommissionDebt}
                  </h3>
                  <h3 className="fs-1">: {t("commission_debt")} </h3>
                </>
              )}
            </div>
            <Suspense
              fallback={
                <div
                  className="spinner-grow text-warning page-loader"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              }
            ></Suspense>
          </div>
          <div className="container pb-5">
            <div className="row menu-row">
              <div className="col menu-col mr-2">
                <div className="row justify-content-between flex-column flex-lg-row align-items-center mt-3">
                  <div className="col-7 row">
                    <button
                      onClick={onSubmit}
                      className="submit-button"
                      type="submit"
                    >
                      <i className="fas fa-search"></i>
                    </button>
                    <input
                      className="form-control"
                      type="search"
                      onChange={onChange}
                      value={searchedVal}
                      placeholder={t("search")}
                      aria-label="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-center d-flex justify-content-center mb-4">
              <Suspense
                fallback={
                  <div
                    className="spinner-grow text-warning data-loader"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                }
              >
                <>
                  <div className="table-responsive-sm w-100 mt-5">
                    <table className="table w-100">
                      <thead>
                        <tr>
                          <th scope="col table-th"></th>

                          <th scope="col table-th" className="table-th">
                            __
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("history")}
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("date")}
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("driver")}
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("sender")}
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("status")}
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("total_price")}
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("receiver_name")}
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("receiver_number")}
                          </th>
                          <th scope="col table-th" className="table-th">
                            {t("barcode")}
                          </th>
                        </tr>
                      </thead>
                      {currentPageData}
                    </table>
                  </div>
                </>
              </Suspense>
            </div>
            {!isLoading && (
              <ReactPaginate
                previousLabel={t("next")}
                nextLabel={t("previous")}
                // pageCount={10}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
                pageClassName={"pagination-li"}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default BranchDetails;
