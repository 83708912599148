export const POST_LOGIN = "POST_LOGIN";
export const PENDING = "PENDING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const GET_SENDERS = "GET_SENDERS";
export const PENDING_GET_SENDERS = "PENDING_GET_SENDERS";
export const GET_SENDERS_FAILED = "GET_SENDERS_FAILED";
export const GET_DRIVERS = "GET_DRIVERS";
export const GET_DRIVERS_FAILED = "GET_DRIVERS_FAILED";
export const POST_LOGOUT = "POST_LOGOUT";
export const ADD_SENDER = "ADD_SENDER";
export const ADD_SENDER_FAILED = "ADD_SENDER_FAILED";
export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_FAILED = "ADD_ORDER_FAILED";
export const ADD_DRIVER = "ADD_DRIVER";
export const ADD_DRIVER_FAILED = "ADD_DRIVER_FAILED";
export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_FAILED = "GET_CITIES_FAILED";
export const GET_PROVINCES = "GET_PROVINCES";
export const GET_PROVINCES_FAILED = "GET_PROVINCES_FAILED";
export const EDIT_SENDER = "EDIT_SENDER";
export const EDIT_SENDER_FAILED = "EDIT_SENDER_FAILED";
export const EDIT_DRIVER = "EDIT_DRIVER";
export const EDIT_DRIVER_FAILED = "EDIT_DRIVER_FAILED";
export const DELETE_SENDER_ORDER = "DELETE_SENDER_ORDER";
export const DELETE_SENDER_ORDER_FAILED = "DELETE_SENDER_ORDER_FAILED";
export const PENDING_LOGIN = "PENDING_LOGIN";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const DELETE_DRIVER_FAILED = "DELETE_DRIVER_FAILED";
export const DELETE_DRIVER_ORDER = "DELETE_DRIVER_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED";
export const GET_SENDER_ORDERS = "GET_SENDER_ORDERS";
export const DELETE_SENDER = "DELETE_SENDER";
export const DELETE_SENDER_FAILED = "DELETE_SENDER_FAILED";
export const PENDING_ADDING_DRIVER = "PENDING_ADDING_DRIVER";
export const PENDING_EDIT_SENDER = "PENDING_EDIT_SENDER";
export const PENDING_EDIT_DRIVER = "PENDING_EDIT_DRIVER";
export const PENDING_ADD_SENDER = "PENDING_ADD_SENDER";
export const PENDING_ORDER = "PENDING_ORDER";
export const PENDING_UPDATE_ORDER = "PENDING_UPDATE_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_FAILED = "UPDATE_ORDER_FAILED";
export const PENDING_GET_ORDERS = "PENDING_GET_ORDERS";
export const PENDING_GET_CITY_FEES = "PENDING_GET_CITY_FEES";
export const GET_CITY_FEES = "GET_CITY_FEES";
export const GET_CITY_FEES_FAILED = "GET_CITY_FEES_FAILED";
export const PENDING_ADD_CITY_FEE = "PENDING_ADD_CITY_FEE";
export const ADD_CITY_FEE = "ADD_CITY_FEE";
export const ADD_CITY_FEE_FAILED = "ADD_CITY_FEE_FAILED";
export const PENDING_UPDATE_CITY_FEE = "PENDING_UPDATE_CITY_FEE";
export const UPDATE_CITY_FEE = "UPDATE_CITY_FEE";
export const UPDATE_CITY_FEE_FAILED = "UPDATE_CITY_FEE_FAILED";
export const DELETE_CITY_FEE = "DELETE_CITY_FEE";
export const DELETE_CITY_FEE_FAILED = "DELETE_CITY_FEE_FAILED";
export const PENDING_GET_INVENTORIES = "PENDING_GET_INVENTORIES";
export const GET_INVENTORIES = "GET_INVENTORIES";
export const GET_INVENTORIES_FAILED = "GET_INVENTORIES_FAILED";
export const PENDING_ADD_INVENTORY = "PENDING_ADD_INVENTORY";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const ADD_INVENTORY_FAILED = "ADD_INVENTORY_FAILED";
export const PENDING_UPDATE_INVENTORY = "PENDING_UPDATE_INVENTORY";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const UPDATE_INVENTORY_FAILED = "UPDATE_INVENTORY_FAILED";
export const DELETE_INVENTORY = "DELETE_INVENTORY";
export const DELETE_INVENTORY_FAILED = "DELETE_INVENTORY_FAILED";
export const PENDING_GET_ADMINS = "PENDING_GET_ADMINS";
export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMINS_FAILED = "GET_ADMINS_FAILED";
export const PENDING_ADD_ADMIN = "PENDING_ADD_ADMIN";
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_FAILED = "ADD_ADMIN_FAILED";
export const PENDING_PATCH_ADMIN = "PENDING_PATCH_ADMIN";
export const PATCH_ADMIN = "PATCH_ADMIN";
export const PATCH_ADMIN_FAILED = "PATCH_ADMIN_FAILED";

export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_FAILED = "DELETE_ADMIN_FAILED";
export const PENIDNG_GET_RULES = "PENIDNG_GET_RULES";
export const GET_RULES = "GET_RULES";
export const GET_RULES_FAILED = "GET_RULES_FAILED";
export const ADD_RULES = "ADD_RULES";
export const ADD_RULES_FAILED = "ADD_RULES_FAILED";
export const DELETE_RULES = "DELETE_RULES";
export const DELETE_RULES_FAILED = "DELETE_RULES_FAILED";
export const UPDATE_RULES = "UPDATE_RULES";
export const UPDATE_RULES_FAILED = "UPDATE_RULES_FAILED";
export const PENDING_GET_REPORTS = "PENDING_GET_REPORTS";
export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_FAILED = "GET_REPORTS_FAILED";
export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_REPORT_FAILED = "DELETE_REPORT_FAILED";
export const PENDING_GET_HISTORY = "PENDING_GET_HISTORY";
export const GET_HISTORIES = "GET_HISTORIES";
export const GET_HISTORIES_FAILED = "GET_HISTORIES_FAILED";
export const DELETE_HISTORY = "DELETE_HISTORY";
export const DELETE_HISTORY_FAILED = "DELETE_HISTORY_FAILED";
export const GET_NEIGHBORHOODS = "GET_NEIGHBORHOODS";
export const PENDING_GET_NEIGHBORHOODS = "PENDING_GET_NEIGHBORHOODS";
export const GET_NEIGHBORHOODS_FAILED = "GET_NEIGHBORHOODS_FAILED";
export const DELETE_NEIGHBORHOOD = "DELETE_NEIGHBORHOOD";
export const DELETE_NEIGHBORHOOD_FAILED = "DELETE_NEIGHBORHOOD_FAILED";
export const ADD_NEGHBORHOOD = "ADD_NEGHBORHOOD";
export const ADD_NEIGHBORHOOD_FAILED = "ADD_NEIGHBORHOOD_FAILED";
export const NEIGHBORHOOD_UPDATED = "NEIGHBORHOOD_UPDATED";
export const UPDATE_NEIGHBORHOOD_FAILED = "UPDATE_NEIGHBORHOOD_FAILED";
export const PENDING_GET_SPECIAL_REQUESTS = "PENDING_GET_SPECIAL_REQUESTS";
export const GET_SPECIAL_REQUESTS = "GET_SPECIAL_REQUESTS";
export const GET_SPECIAL_REQUESTS_FAILED = "GET_SPECIAL_REQUESTS_FAILED";
export const DELETE_SPECIAL_REQUEST = "DELETE_SPECIAL_REQUEST";
export const DELETE_SPECIAL_REQUEST_FAILED = "DELETE_SPECIAL_REQUEST_FAILED";
export const PENDING_GET_BRANCHES = "PENDING_GET_BRANCHES";
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_BRANCHES_FAILED = "GET_BRANCHES_FAILED";
export const PENDING_ADD_BRANCH = "PENDING_ADD_BRANCH";
export const ADD_BRANCH = "ADD_BRANCH";
export const ADD_BRANCH_FAILED = "ADD_BRANCH_FAILED";
export const DELETE_BRANCH = "DELETE_BRANCH";
export const DELETE_BRANCH_FAILED = "DELETE_BRANCH_FAILED";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_BRANCH_FAILED = "UPDATE_BRANCH_FAILED";
